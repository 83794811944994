import { environment } from '@env/environment';

const ApiRoutes = {
    LOGIN: environment.APP_API_URL + "/login",
    LOGOUT: environment.APP_API_URL + "/logout",
    refresh: environment.APP_WEB_URL + "/oauth/token",
    profile: environment.APP_API_URL + "/profile",
    listUser: environment.APP_API_URL + "/assets/json/userList.json",
}

export {
    ApiRoutes
}