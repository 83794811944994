import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {AuthService} from '../../auth/auth.service';
//import {FormGroup, FormBuilder, FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
  }

  logout(): void {
    let Bearer:any = localStorage.getItem('token');
    let formData = new FormData();
    formData.append("token", Bearer);
    this.authService.logout(formData).subscribe((result:any)=> {
      this.toastr.success("Logged Out Successfully...!")
      localStorage.removeItem("token");
      localStorage.removeItem("usertype");
      localStorage.removeItem("id");
      localStorage.removeItem("refresh-token");
      localStorage.removeItem("expires-in");
      this.router.navigate(['/']);     
    })
  }

}
