import {  FormBuilder, FormGroup, FormArray, Validators } from "@angular/forms";

let fb = new FormBuilder();
let fbGroup = new FormBuilder();
let fbArray = new FormBuilder();

let AlarmSettingForm: FormGroup = fb.group({
    id: [''],
    alarm_name: ['', Validators.required],
    equipment_model_id:['',Validators.required],
    emails:fbArray.array([]),
    charts:fbArray.array([]),
    created_by :[''],
})

export {
    AlarmSettingForm
}
