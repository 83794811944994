import {  FormBuilder, FormGroup, FormArray, Validators } from "@angular/forms";

let fb = new FormBuilder();
let fbGroup = new FormBuilder();
let fbArray = new FormBuilder();

let ChartDefinitionListForm: FormGroup = fb.group({
    created_by :[''],
    chart_id:[''],
    parameters:fbArray.array([
        // fbGroup.group({
        //     parameter_id:['',Validators.required],
        // }),
    ]),
    
})

export {
    ChartDefinitionListForm
}
