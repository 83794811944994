import { Component } from '@angular/core';
import { environment } from '../environments/environment';
import {AuthService} from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'pro-cim';
  constructor(
    private authService: AuthService
  ) {}
  
  ngOnInit() {
    setInterval(() => {
        let expires_in : any = localStorage.getItem('expires-in') ? localStorage.getItem('expires-in') : 0;
        if(localStorage.getItem("token") && expires_in && expires_in > 0 && expires_in < 100) {
            let postObj = {
                grant_type:environment.GRANT_TYPE_REFRESH,
                refresh_token:localStorage.getItem('refresh-token'),
                client_id:environment.CLIENT_ID,
                client_secret:environment.CLIENT_SECRET_KEY,
                scope:''
            }
            this.authService.refresh(postObj).subscribe((result:any) => {
                localStorage.setItem('token', result.access_token);
                localStorage.setItem('refresh-token', result.refresh_token);
                localStorage.setItem('expires-in', result.expires_in);
            });
        } else if (expires_in > 0) {
            let expiresIn : any = expires_in - 10;
            localStorage.setItem('expires-in', expiresIn);
        }
    }, 12500);
  }
    
}
