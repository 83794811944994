<form [formGroup]="userCreationForm" class="text-left" [ngClass]="{'form-validated': submitted}" (ngSubmit)="addUser()">
    <div class="row">
        <div class="col-md-4">
            <div class="form-group">
                <label for="user_name">User name</label>
                <input type="text" id="user_name" class="form-control" formControlName="user_name" placeholder="Your Username">
                <app-form-error [controlObj]="userCreationForm.get('user_name')" label="Username"></app-form-error>
            </div>
        </div>
        <div class="col-md-4" >
            <div class="form-group">
                <label for="email_id">Email Id</label>
                <input type="text" id="email_id" class="form-control" formControlName="email" placeholder="Your Email Id">
                <app-form-error [controlObj]="userCreationForm.get('email')" label="Email Id"></app-form-error>
            </div>
        </div>
        <div class="col-md-4" *ngIf="!user.value.id">
            <div class="form-group">
                <label for="password">Password</label>
                <input type="password" id="password" class="form-control" formControlName="password" (keypress)="emailValidations($event)" placeholder="Your password">
                <app-form-error [controlObj]="userCreationForm.get('password')" label="password"></app-form-error>
            </div>
        </div>
        <div class="col-md-4" *ngIf="!user.value.id">
            <div class="form-group">
                <label for="confirm_password">Confirm Password</label>
                <input type="password" id="confirm_password" class="form-control" formControlName="confirm_password" placeholder="Confirm password">
                <app-form-error [controlObj]="userCreationForm.get('confirm_password')" label="confirm_password"></app-form-error>
            </div>
        </div>
        <div class="col-md-4" >
            <div class="form-group">
                <label for="first_name">First name</label>
                <input type="text" id="first_name" class="form-control" formControlName="first_name" placeholder="Your Firstname">
                <app-form-error [controlObj]="userCreationForm.get('first_name')" label="Firstname"></app-form-error>
            </div>
        </div>

        <div class="col-md-4" >
            <div class="form-group">
                <label for="last_name">Last name</label>
                <input type="text" id="last_name" class="form-control" formControlName="last_name" placeholder="Your Lastname">
                <app-form-error [controlObj]="userCreationForm.get('last_name')" label="Lastname"></app-form-error>
            </div>
        </div>

        <div class="col-md-4" >
            <div class="form-group">
                <label for="mobile">Mobile</label>
                <input type="text" id="mobile" class="form-control" formControlName="mobile" (keypress)="numberOnly($event)" placeholder="Your Mobile">
                <app-form-error [controlObj]="userCreationForm.get('mobile')" label="Mobile"></app-form-error>
            </div>
        </div>

        <div class="col-md-4" >
            <div class="form-group">
                <label for="department_id">Department</label>
                <select id="department_id" class="form-control" formControlName="department_id">
                    <option *ngFor="let department of departments" value="{{department.id}}">{{department.name}}</option>
                </select>
                <app-form-error [controlObj]="userCreationForm.get('department')" label="Department"></app-form-error>
            </div>
        </div>

        <div class="col-md-4" >
            <div class="form-group">
                <label for="designation_id">Designation</label>
                <select id="designation_id" class="form-control" formControlName="designation_id">
                    <option *ngFor="let designation of designations" value="{{designation.id}}" >
                        {{designation.name}}
                    </option>
                </select>
                <app-form-error [controlObj]="userCreationForm.get('designation')" label="Designation"></app-form-error>
            </div>
        </div>        
    </div>

    <h4> Menu </h4>

    <div class="row form-group" formArrayName="user_menu">
        <div class="col-md-2" *ngFor="let menu of menus; let i=index">
            <label>
                <input type="checkbox" [value]="menu.id" (change)="onCheckboxChange($event)" [checked]="menu.isChecked" />
                {{menu.name}}
            </label>
        </div>

        <!-- <div class="col-md-2" *ngFor="let menu of menus; let i = index" >
            <div class="custom-control custom-checkbox">
                <input type="checkbox" formArrayName="user_menu" class="custom-control-input" id="menu_{{i}}" value="{{menu.id}}" (click)="addMenus(menu.id)">
                <label formArrayName="user_menu" class="custom-control-label" for="menu_{{i}}">{{menu.name}}</label>
            </div>
        </div>          -->
    </div>
 

    <div class="row">
        <div class="col-md-12">
            <div class="pull-right">
                <button type="submit" class="btn btn-primary" [disabled]="buttonDisable" *ngIf="!user.value.id">Submit
                </button>&nbsp;
                <button class="btn btn-danger " type="submit" [disabled]="buttonDisable" *ngIf="user.value.id">Update
                </button>&nbsp;
                <button class="btn btn-primary " type="button" (click)="cancel()">Cancel</button>
            </div>
        </div>
    </div>

</form>
