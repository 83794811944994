import {  FormBuilder, FormGroup, FormArray, Validators } from "@angular/forms";

let fb = new FormBuilder();
let fbGroup = new FormBuilder();
let fbArray = new FormBuilder();

let AlarmEmailForm: FormGroup = fb.group({
    id: [''],
    emails:fbArray.array([]),
    created_by :[''],
})

export {
    AlarmEmailForm
}
