import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from "rxjs/operators";
import { ApiRoutes } from '@constants/api.constant';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  // Login
  login(loginForm: any):Observable<any>{
    return this.http.post(ApiRoutes.LOGIN,loginForm).pipe(
        catchError((error : HttpErrorResponse) => {
          return throwError(error);
        })
      )
  }

  refresh(postObj:any):Observable<any>{
    return this.http.post(ApiRoutes.refresh, postObj).pipe(
      catchError((error : HttpErrorResponse) => {
        return throwError(error);
      })
    )
  }
  
  logout(Bearer:any):Observable<any> {
    return this.http.post(ApiRoutes.LOGOUT, Bearer);
  }
  
  // Profile data
  profileData():Observable<any>{
    return this.http.get(ApiRoutes.profile);
  }

  isLoggedIn(): boolean {
    return !!localStorage.getItem("token");
  }
  
  getLoginId() : string {
    return JSON.parse(localStorage.getItem("id") || '1');
  }

  getToken(): string | null {
    return localStorage.getItem("token");
  }

  getUserType(): string {
    return localStorage.getItem("usertype") || "";
  }

  getPermissions(): Array<string> {
    let permissionList = localStorage.getItem("permissions") || "[]";
    let permissions = JSON.parse(permissionList)
    permissions = permissions.filter((p: any) => p.isActive).map((p: any) => p.action_key)
    return permissions
  }
  
}