<form [formGroup]="alarmSettingForm" class="text-left" [ngClass]="{'form-validated': submitted}" (ngSubmit)="createAlarm()">
    <div class="row">
        <div class="col-md-4">
            <div class="form-group">
                <label for="alarm_name">Alarm name</label>
                <input type="text" id="alarm_name" class="form-control" formControlName="alarm_name" placeholder="Your Alarm name">
                <app-form-error [controlObj]="alarmSettingForm.get('alarm_name')" label="Alarm name"></app-form-error>
            </div>
        </div>
        
        <div class="col-md-4" >
            <div class="form-group">
                <label for="equipment_model_id">Equipment Model</label>
                <select id="equipment_model_id" class="form-control" formControlName="equipment_model_id">
                    <option hidden value="" disabled selected>Select </option>
                        <option [value]="equipment.id" *ngFor="let equipment of equipments">{{equipment.name}}</option> 
                </select>
                <app-form-error [controlObj]="alarmSettingForm.get('equipment_model_id')" label="Equipment Model"></app-form-error>
            </div>
        </div>
    </div>
    
    <div class="row">
        <h4> Add Emails </h4> 
        <button type="button" class="btn btn-primary btn-sm 
        common-btn ml-2 mb-2"(click)="AddMoreEmail()">Add Emails </button>
    </div>
    <div formArrayName="emails">
        <div *ngFor="let mail of email.controls; let i = index;">
           <div [formGroupName]="i">
            <div class="row">
                <div class="col-md-4" >
                    <div class="form-group">
                        <label for="">Emails</label>
                        <select id="email" class="form-control" formControlName="email_id" required>
                            <option hidden value="" disabled selected>Select </option>
                                <option [value]="email.id" *ngFor="let email of emails">{{email.email}}</option> 
                        </select>
                          <app-form-error [controlObj]="mail.get('email_id')" label="Email"></app-form-error>
                          <span (click)="deleteEmail(i)" class=" mt-2 ml-2 fa fa-times text-danger"></span>
                    </div>
                </div>
            </div>
           </div>
           
        </div>
    </div>


    <div class="row">
        <h4> Add Charts </h4> 
        <button type="button" class="btn btn-primary btn-sm 
        common-btn ml-2 mb-2"(click)="AddMoreChart()">Add Charts</button>

    </div>    
    <div formArrayName="charts">
        <div *ngFor="let ch of chart.controls; let i = index;">
           <div [formGroupName]="i">
            <div class="row">
                <div class="col-md-4" >
                    <div class="form-group">
                        <label for="">Charts</label>
                        <select id="chart" class="form-control" formControlName="chart_id" required>
                            <option hidden value="" disabled selected>Select </option>
                                <option [value]="charts.id" *ngFor="let charts of chartsList">{{charts.chart_name}}</option> 
                        </select>
                          <app-form-error [controlObj]="ch.get('chart_id')" label="Chart"></app-form-error>
                          <span (click)="deleteChart(i)" class=" mt-2 ml-2 fa fa-times text-danger"></span>
                    </div>
                </div>
            </div>
           </div>
        </div>
    </div>


    <div class="row">
        <div class="col-md-12">
            <div class="pull-right">
                <button type="submit" class="btn btn-primary" [disabled]="buttonDisable" *ngIf="!alarmSetting.value.id">Submit
                </button>&nbsp;
                <button class="btn btn-danger " type="submit" [disabled]="buttonDisable" *ngIf="alarmSetting.value.id">Update
                </button>&nbsp;
                <button class="btn btn-primary " type="button" (click)="cancel()">Cancel</button>
            </div>
        </div>
    </div>

</form>
