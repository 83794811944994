import {  FormBuilder, FormGroup, FormArray, Validators } from "@angular/forms";

let fb = new FormBuilder();
let fbGroup = new FormBuilder();
let fbArray = new FormBuilder();

let ChartDefinitionForm: FormGroup = fb.group({
    id: [''],
    chart_name: ['', Validators.required],
    equipment_model_id:['',Validators.required],
    parameter_usl: ['', Validators.required],
    parameter_ucl: ['', Validators.required],
    center_line: ['', Validators.required],
    parameter_lcl: ['', Validators.required],
    parameter_lsl: ['',Validators.required],
    parameters:fbArray.array([
        // fbGroup.group({
        //     parameter_id:['',Validators.required],
        // }),
    ]),
    created_by :[''],
})

export {
    ChartDefinitionForm
}
