import {  FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";

let fb = new FormBuilder();

let designationForm: FormGroup = fb.group({
    id:[''],
    name:['',Validators.required],
    created_by:['']
})

export {
    designationForm
}
