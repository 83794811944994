import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OverviewComponent } from './overview/overview.component';
import { LayoutsModule } from '../layouts/layouts.module';
import { AdminLayoutComponent } from '../layouts/admin-layout/admin-layout.component';
import { AdminGuard } from './admin.guard';
import { CreateUserComponent } from './user-creation/create-user/create-user.component';
import { UserListComponent } from './user-creation/user-list/user-list.component';
import { DepartmentComponent } from './department/department.component';
import { DesignationComponent } from './designation/designation.component';
import { EquipmentComponent } from './equipment/equipment.component';
import { ParameterListComponent } from './parameter/parameter-list/parameter-list.component';
import { CreateParameterComponent } from './parameter/create-parameter/create-parameter.component';
import { SvidChamperComponent } from './svid-champer/svid-champer.component';
import { DuplicateParameterComponent } from './parameter/duplicate-parameter/duplicate-parameter.component';
import { CreateChartDefinitionComponent } from './chart-definition/create-chart-definition/create-chart-definition.component';
import { ChartDefinitionListComponent } from './chart-definition/chart-definition-list/chart-definition-list.component';
import { SpcChartComponent } from './spc-chart/spc-chart.component';
import { EmailListComponent } from './email-creation/email-list/email-list.component';
import { CreateEmailComponent } from './email-creation/create-email/create-email.component';

import { AlarmListComponent } from './alarm-setting/alarm-list/alarm-list.component';
import { CreateAlarmComponent } from './alarm-setting/create-alarm/create-alarm.component';

const routes: Routes = [
  {
    path: "admin",
    component: AdminLayoutComponent,
    canActivate: [AdminGuard],
    children: [
      {path: "overview", component: OverviewComponent, data: { breadcrumb: 'Overview'}},      
      //User Creation Routing
      {path: "user-list", component: UserListComponent, data: { breadcrumb: 'Admin / User List'}},
      {path: "create-user", component: CreateUserComponent, data: { breadcrumb: 'Admin / Create User'}},
      {path: 'user/edit/:id', component:CreateUserComponent , data: { breadcrumb: 'Admin / User Edit'}},
      {path: 'department', component:DepartmentComponent , data: { breadcrumb: 'Admin / Department'}},
      {path: 'designation', component:DesignationComponent , data: { breadcrumb: 'Admin / Designation'}},
      {path: 'equipment', component:EquipmentComponent , data: { breadcrumb: 'Admin / Equipment'}},
      {path: 'parameter-list', component:ParameterListComponent , data: { breadcrumb: 'Admin / Parameter List'}},
      {path: 'create-parameter', component:CreateParameterComponent , data: { breadcrumb: 'Admin / Create Parameter'}},
      {path: 'parameter/edit/:id', component:CreateParameterComponent , data: { breadcrumb: 'Admin / Parameter Edit'}},
      {path: 'parameter/duplicate/:id', component:DuplicateParameterComponent , data: { breadcrumb: 'Admin / Parameter Duplicate'}},

      {path: 'svid-chamber',component:SvidChamperComponent , data :{breadcrumb:'Admin / Create SVID-Chamber'}},

      {path: 'chart-definition-list', component:ChartDefinitionListComponent , data: { breadcrumb: 'Admin / Chart Definition List'}},
      {path: 'create-chart-definition', component:CreateChartDefinitionComponent , data: { breadcrumb: 'Admin / Create Chart Definition'}},
      {path: 'chart-definition/edit/:id', component:CreateChartDefinitionComponent , data: { breadcrumb: 'Admin / Parameter Edit'}},

      {path: 'spc-chart', component:SpcChartComponent , data: { breadcrumb: 'Admin / SPC Chart'}},

      {path: 'email-list', component:EmailListComponent , data: { breadcrumb: 'Admin / Email List'}},
      {path: 'create-email', component:CreateEmailComponent , data: { breadcrumb: 'Admin / Create Email'}},
      {path: 'email/edit/:id', component:CreateEmailComponent , data: { breadcrumb: 'Admin / Email Edit'}},

      {path: 'alarm-list', component:AlarmListComponent , data: { breadcrumb: 'Admin / Alarm List'}},
      {path: 'create-alarm', component:CreateAlarmComponent , data: { breadcrumb: 'Admin / Create Alarm Setting'}},
      {path: 'alarm/edit/:id', component:CreateAlarmComponent , data: { breadcrumb: 'Admin / Alarm Edit'}},

    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    LayoutsModule
  ],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
