<div class="row">
    <div class="col-md-3 mt-2">

        <button type="button" class="btn btn-primary btn-sm count-widget mb-2">
            <i class="fas fa-folder-open "></i>
            Total
            <span class="badge badge-info rounded-circle ml-1">{{count}}</span>
        </button>
        <button type="button" class="btn btn-primary btn-sm common-btn ml-2 mb-2" [routerLink]="['/admin/create-chart-definition']">
                <i class="fas fa-plus-circle"></i>
            Add
        </button>
        <button type="button" class="btn btn-primary btn-sm common-btn ml-2 mb-2" >
            <i class="fas fa-sync-alt" (click)="refresh()"></i>
        </button>
    </div>

    <div class="d-flex flex-row-reverse col-md-9" >
            <div class="p-2 mb-2">
                <form [formGroup]="search" (ngSubmit)="getSearchList()" title="Search" >
                    <div class="float-right quick-search-wrapper">
                        <div class="form-inline">
                            <input class="form-control" formControlName="search" name="search1"
                                type="search" placeholder="Quick Search" aria-label="quick-search">
                            <button type="submit" class="btn btn-danger" (click)="getSearchList()">
                                <i class="fas fa-search"></i>
                            </button>
                        </div>
                    </div>
                </form>
        </div>
     
    </div>
</div>

<div class="table-responsive-sm table-responsive-md table-responsive-lg table-responsive-xl" style="overflow-x:auto;">
    <!-- <div class="card"> -->
    <table class="table table-bordered">
        <thead>
            <tr>
                <th>#</th>
                <th>Chart Name</th>
                <th>Parameter Name</th>
                <th>Created At</th>
                <th>Updated At</th>
                <th>Action</th>
            </tr>
        </thead>

        <tbody>
            <tr *ngIf="showLoader">
                <td colspan="6" class="text-center">
                    <i class="fas fa-spinner fa-2x fa-spin mx-3" aria-hidden="true"></i>
                </td>
            </tr>
            <tr *ngIf="chartList && chartList.length == 0 && !showLoader">
                <td colspan="6" class="text-center"> No Records Found</td>
            </tr>
            <tr *ngFor="let chart of chartList | paginate: { itemsPerPage: 10, currentPage: pagination } let i=index;">
                <td>{{i + 1 }}</td>
                <td>{{chart.chart_name}}</td>
                <td ><button class="btn btn-link btn-sm btn-icon-only" data-toggle="modal" 
                    data-target="#parameterModal" (click)="viewParameter(chart)">
                    <i class="fa fa-eye"
                        aria-hidden="true"></i>&nbsp;View Parameter</button></td>
                <td>{{chart.created_at}}</td>
                <td>{{chart.updated_at}}</td>
                <td>
                    <a class="btn btn-link btn-sm btn-icon-only" [routerLink]="['/admin/chart-definition/edit/',chart.id]" title="Edit">
                       <i class="fa fa-edit"></i>
                    </a>
                    <a class="btn btn-link btn-sm btn-icon-only" title="Delete" (click)="delete(chart.id)">
                        <i class="fa fa-trash"></i>
                    </a>
                    <a class="btn btn-link btn-sm btn-icon-only" title="Add parameter" data-toggle="modal" data-target="#AddParameterModal" (click)="openModal(chart)">
                        <i class="fa fa-plus"></i>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<pagination-controls (pageChange)="pagination = $event"></pagination-controls>



<div class="modal fade" id="parameterModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
aria-hidden="true">
<div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalScrollableTitle">&nbsp;Parameters</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="card">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th> # </th>
                            <th>Parameter Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="showLoader">
                            <td colspan="4" class="text-center">
                                <i class="fas fa-spinner fa-2x fa-spin mx-3" aria-hidden="true"></i>
                            </td>
                        </tr>
                        <tr *ngIf="parameters && parameters.length == 0 && !showLoader">
                            <td colspan="4" class="text-center"> No Records Found</td>
                        </tr>
                        <tr *ngFor="let parameter of parameters; let i=index;">
                            <td>{{ i + 1}}</td>
                            <td>{{ parameter.parameter.name }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
</div>



<div class="modal fade" id="AddParameterModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
aria-hidden="true">
<div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalScrollableTitle">&nbsp;Add Parameters</h5>
            <button type="button" #closebutton class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <button type="button" *ngIf="addMoreButtonVisible == true" class="btn btn-primary btn-sm common-btn ml-2 mb-2"(click)="AddMoreParameter()">    
                Add Parameter
            </button>
                <form [formGroup]="chartDefinitionList" class="text-left" [ngClass]="{'form-validated': submitted}" (ngSubmit)="save()">
                    <div formArrayName="parameters">
                        <div *ngFor="let item of userParameter.controls; let i=index;">
                            <div [formGroupName]="i">
                                <div class="row">
                                    <div class="col-md-4" >
                                        <div class="form-group">
                                            <label for="parameter_id">Parameter</label>
                                            <ng-autocomplete
                                                [data]="userParameters"
                                                [searchKeyword]="keyword"
                                                (selected)='selectParameter($event)'
                                                [itemTemplate]="itemTemplate"
                                                [notFoundTemplate]="notFoundTemplate"
                                                formControlName = "parameter_id"
                                                placeholder="Enter Parameter">
                                            </ng-autocomplete>
                            
                                            <ng-template #itemTemplate let-parameter>
                                                <a [innerHTML]="parameter.name"></a>
                                              </ng-template>
                                            
                                              <ng-template #notFoundTemplate let-notFound>
                                                <div [innerHTML]="notFound"></div>
                                              </ng-template>
                                            <app-form-error [controlObj]="item.get('parameter_id')" label="Parameter"></app-form-error>
                                            <span (click)="deleteParameter(i)" class=" mt-2 ml-2 fa fa-times text-danger"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            

                        </div>
                    </div>

                    <button type="button" class="btn btn-secondary" style="float: right;" data-dismiss="modal" (click)="closeModal()">Close</button>
                    <button type="submit" class="btn btn-success mr-2" style="float: right;">Submit</button>

                </form>
            
        </div>

    </div>
</div>
</div>

