import { Component, OnInit } from '@angular/core';
import { UserCreationForm } from './user-creation.form';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup,FormArray,FormControl,FormBuilder } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { adminApi } from '../../admin.api';
import { STATUS_ACTIVE } from '@constants/global.constant';
    
@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {

  userCreationForm: FormGroup = UserCreationForm;
  showLoader: boolean = false;
  submitted: boolean = false;
  buttonDisable:boolean = false;
  user:any;
  departments:any;
  designations:any;
  menus:any;
  userId:any = null;
  userMenu:any;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.user = this.userCreationForm;
    this.getAllMenus();
    this.getActiveDepartments();
    this.getActiveDesignations();
  }

  getActiveDepartments() {
    this.http.get(adminApi.departmentByStatus(STATUS_ACTIVE)).subscribe((result:any) => {
      this.departments = result.data;
    });
  }
  
  getActiveDesignations() {
    this.http.get(adminApi.designationByStatus(STATUS_ACTIVE)).subscribe((result:any) => {
      this.designations = result.data;
    });
  }
  
  getAllMenus() {
    this.http.get(adminApi.menuListAll()).subscribe((result:any) => {
      this.menus = result.data;
    this.route.paramMap.subscribe(params => {
      this.userId = params.get('id');
      if(this.userId) {
        this.getUser(this.userId);
//        this.user.removeControl('password');
//        this.user.removeControl('confirm_password');
        this.user.controls['password'].clearValidators();
        this.user.controls["password"].updateValueAndValidity();

//        this.user.get('password').setValidators('');
        this.user.get('confirm_password').setValidators('');
        this.user.controls["confirm_password"].updateValueAndValidity();
      }
    })          
    });
  }
  
  getUser(userId:any) {
    this.http.get(adminApi.userById(userId)).subscribe((result:any) => {
      let userMenuId:any = [];
      result.data[0].user_menu.forEach(function(value:any, key:any) {
        userMenuId.push(value.menu_id);
      });
      this.user.patchValue(result.data[0]);   
      this.userMenu = this.user.get('user_menu') as FormArray;
      let parent = this;
      this.userMenu.clear();
      this.menus.forEach(function(value:any, key:any) {
        let isPresent = userMenuId.some(function(el:any){ return el == value.id});
          if(isPresent) {
            value.isChecked = true;
            parent.userMenu.push(new FormControl(value.id));
          }
      });
    });
  }

  addUser(){
    this.submitted = true;
    this.showLoader = true;
    this.buttonDisable = true;
    if(this.user.invalid) {
      this.buttonDisable = false; 
      return;
    }
    if(this.user.value.id) {
      this.http.put(adminApi.updateUser(),this.user.value).subscribe((result:any) => {
        this.userCreationForm.reset();
        this.user.reset();
        this.submitted = false;
        this.toastr.success("User updated successfully!");
        this.router.navigate(['/admin/user-list']);
      },(errorObj: any) => {
        var errors:any = Object.values(errorObj.error.errors).map((el:any) => el[0]);
        this.toastr.error(errorObj.error.message);
        this.toastr.error(errors);
        this.submitted = false;
        this.showLoader = false;
        this.buttonDisable = false;
      });
    } else {
      this.http.post(adminApi.createUser(),this.user.value).subscribe((result:any) => {
        this.userCreationForm.reset();
        this.user.reset();
        this.submitted = false;
        this.toastr.success("User added successfully!");
        this.router.navigate(['/admin/user-list']);
      },(errorObj: any) => {
        var errors:any = Object.values(errorObj.error.errors).map((el:any) => el[0]);
        this.toastr.error(errorObj.error.message);
        this.toastr.error(errors);
        this.submitted = false;
        this.showLoader = false;
        this.buttonDisable = false;
      });
    }
  }

  emailValidations(event : any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (!(charCode >= 64 && charCode <= 90) && !(charCode >= 97 && charCode <= 122) && (charCode != 32 && charCode != 8) && !(charCode >= 45 && charCode <= 57) && !(charCode == 95) && !(charCode == 9) || (charCode == 47)) {  
      return false;  
    }  
   return true;  
  }
  
  numberOnly(event : any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 43 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
   return true;
  }
  
  cancel() {
    this.userCreationForm.reset();
    this.user.reset();
    this.router.navigate(['/admin/user-list']);
  }
    
  
  onCheckboxChange(e:any) {
    if(!this.userId) {
      this.userMenu = this.user.get('user_menu') as FormArray;
    }
    let index:any = this.userMenu.value.findIndex((x:any) => x == e.target.value);
    
    if (e.target.checked) {
      this.userMenu.push(new FormControl(parseInt(e.target.value)));
    } else if (index != -1) {
//      this.userMenu.value.splice(index, 1);
      this.userMenu.removeAt(this.userMenu.value.findIndex((x:any) => x == e.target.value));
    }
//    let unique = new Set(this.userMenu.value);
//    this.userMenu.patchValue(unique);
//    console.log(unique);
    console.log(this.userMenu.value);
  }

}
