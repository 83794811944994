// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // Local
  // APP_WEB_URL: "http://localhost/video-survey/precim-backend/public/index.php",
  // APP_API_URL: "http://localhost/video-survey/precim-backend/public/index.php/api",
   APP_WEB_URL: "https://devtest1.assistanz.net/backend/public/index.php",
   APP_API_URL: "https://devtest1.assistanz.net/backend/public/index.php/api",
   CLIENT_SECRET_KEY : "5RC1hGGPv4HYdX1PMqYRiZX6TwGSJsUBPEcbTDpp",
   ENV : "development",
     
  // Staging
// WEB_URL: "https://backstaging.vidmagin.com/public/index.php",
// API_URL: "https://backstaging.vidmagin.com/public/index.php/api",
// LOGIN_URL : "https://backstaging.vidmagin.com/public/index.php",
// CLIENT_SECRET_KEY : "T4Vk7nXpDvfZbfnpusiyHvIiC0Issiyvr81XxvtR",
// ENV : "staging",
  
  // Production
  // WEB_URL: "https://backend.vidmagin.com/public/index.php",
  // API_URL: "https://backend.vidmagin.com/public/index.php/api",
  // CLIENT_SECRET_KEY : "vu7fMToF2iDTJWPfq0ytBSAuqAoE9A3stIVR2tTR",
  // ENV : "production",
  CLIENT_ID : "2",
  GRANT_TYPE : "password",
  GRANT_TYPE_REFRESH : "refresh_token",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
