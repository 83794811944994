import { Component, OnInit, Directive, HostListener, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { departmentForm } from './departmentForm';
import { searchForm } from '../../shared/forms/searchForm';
import { ToastrService } from 'ngx-toastr';
import { AuthService} from '../../auth/auth.service';
import { FormsModule, FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { adminApi } from '../admin.api';

@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.css']
})

export class DepartmentComponent implements OnInit {

  departmentsList:any = [];
  submitted: boolean = false;
  admin_id?:any = null;
  deptForm:any;
  searchForm:any;
  search:any;
  showLoader:boolean = false;
  closeResult:string= "";
  pagination:any;
      
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private authService: AuthService,
    private fb: FormBuilder,
  ) { }

    ngOnInit() {
      if(this.authService.isLoggedIn()) {
          this.admin_id = this.authService.getLoginId();
      }
      this.searchForm = searchForm;
      this.getDepartments();
    }
    
    openModal(content:any) {
      this.deptForm = departmentForm;
      this.deptForm.get('created_by').setValue(this.authService.getLoginId());
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
}
  
openEditModal(content:any, dept:any) {
    this.deptForm = departmentForm;
    this.deptForm.get('id').setValue(dept.id);
    this.deptForm.get('name').setValue(dept.name);
    this.deptForm.get('created_by').setValue(this.admin_id);    
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
}

  private getDismissReason(reason: any): string {

    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }

  }
    
  getDepartments() {
    this.showLoader = true;
    let pageUrl: string = "?search=" + this.searchForm.value.search;
    this.http.get(adminApi.departmentList(pageUrl)).subscribe((result:any) => {
        this.departmentsList = result.data;
        this.showLoader = false;
    });        
}

  save() {
    this.submitted = true;
    if(this.deptForm.invalid) {
      return;
    }
    if (this.deptForm.value.id) {  
        this.http.put(adminApi.updateDepartment(), this.deptForm.value).subscribe((result:any) => {
            this.toastr.success("Department updated successfully");
            this.submitted = false;
            this.deptForm.reset();
            this.refresh();
            let el = document.getElementById("cancel-modal");
            if(el) el.click();
        },(errorObj: any) => {
            let errors:any = Object.values(errorObj.error.errors).map((el:any) => el[0]);
            this.toastr.error(errors);
            this.toastr.error(errorObj.error.message);
            this.showLoader = false;
        });
    } else {
        this.http.post(adminApi.createDepartment(), this.deptForm.value).subscribe((result:any) => {
            this.toastr.success("Department added successfully");
            this.submitted = false;
            this.deptForm.reset();
            this.refresh();
            let el = document.getElementById("cancel-modal");
            if(el) el.click();
        },(errorObj: any) => {
            var errors:any = Object.values(errorObj.error.errors).map((el:any) => el[0]);
            this.toastr.error(errors);
            this.toastr.error(errorObj.error.message);
            this.showLoader = false;
        });
    }
}

deleteDepartment(id:any){
    if (confirm("Are you sure you want to delete?")) {
        this.http.delete(adminApi.deleteDepartment(id)).subscribe((result) => {
            this.toastr.success("Department deleted successfully");
            this.refresh();
        });
    }
}

refresh() {
    this.searchForm.get('search').setValue("");
    this.getDepartments();
}

}