
<form [formGroup]="chartDefinitionForm" class="text-left" [ngClass]="{'form-validated': submitted}" (ngSubmit)="addChart()">
    <div class="row">
        <div class="col-md-4">
            <div class="form-group">
                <label for="chart_name">Chart name</label>
                <input type="text" id="chart_name" class="form-control" formControlName="chart_name" placeholder="Your Chart name">
                <app-form-error [controlObj]="chartDefinitionForm.get('chart_name')" label="Chart name"></app-form-error>
            </div>
        </div>
        
        <div class="col-md-4" >
            <div class="form-group">
                <label for="equipment_model_id">Equipment Model</label>
                <select id="equipment_model_id" class="form-control" formControlName="equipment_model_id">
                    <option hidden value="" disabled selected>Select </option>
                        <option [value]="equipment.id" *ngFor="let equipment of equipments">{{equipment.name}}</option> 
                </select>
                <app-form-error [controlObj]="chartDefinitionForm.get('equipment_model_id')" label="Equipment Model"></app-form-error>
            </div>
        </div>

        <div class="col-md-4" >
            <div class="form-group">
                <label for="parameter_usl">Parameter USL</label>
                <input type="text"  (keypress)="numberOnly($event)" id="parameter_usl" class="form-control" formControlName="parameter_usl" placeholder="Your Parameter USL">
                <app-form-error [controlObj]="chartDefinitionForm.get('parameter_usl')" label="Parameter USL"></app-form-error>
            </div>
        </div>

        <div class="col-md-4" >
            <div class="form-group">
                <label for="parameter_ucl">Parameter UCL</label>
                <input type="text"  (keypress)="numberOnly($event)" id="parameter_ucl" class="form-control" formControlName="parameter_ucl" placeholder="Your Parameter UCL">
                <app-form-error [controlObj]="chartDefinitionForm.get('parameter_ucl')" label="Parameter UCL"></app-form-error>
            </div>
        </div>

        <div class="col-md-4" >
            <div class="form-group">
                <label for="center_line">Center Line</label>
                <input type="text" (keypress)="numberOnly($event)" id="center_line" class="form-control" formControlName="center_line" placeholder="Your Center Line">
                <app-form-error [controlObj]="chartDefinitionForm.get('center_line')" label="Center Line"></app-form-error>
            </div>
        </div>

        <div class="col-md-4" >
            <div class="form-group">
                <label for="parameter_lcl">Parameter LCL</label>
                <input type="text"  (keypress)="numberOnly($event)" id="parameter_lcl" class="form-control" formControlName="parameter_lcl" placeholder="Your Parameter LCL">
                <app-form-error [controlObj]="chartDefinitionForm.get('parameter_lcl')" label="Parameter LCL"></app-form-error>
            </div>
        </div>


        <div class="col-md-4" >
            <div class="form-group">
                <label for="parameter_lsl">Parameter LSL</label>
                <input type="text"  (keypress)="numberOnly($event)" id="parameter_lsl" class="form-control" formControlName="parameter_lsl" placeholder="Your Parameter LSL">
                <app-form-error [controlObj]="chartDefinitionForm.get('parameter_lsl')" label="Parameter LSL"></app-form-error>
            </div>
        </div>  
    </div>
    
    <div class="row">
        <h4> Add Parameters </h4> <button type="button" class="btn btn-primary btn-sm common-btn ml-2 mb-2"(click)="AddMoreParameter()">    
            Add Parameter
        </button>
    </div>
    
   
    <div formArrayName="parameters">
        <div *ngFor="let param of parameter.controls; let i = index;">
           <div [formGroupName]="i">
            <div class="row">
                <div class="col-md-4" >
                    <div class="form-group">
                        <label for="parameter_id">Parameter</label>
                        <ng-autocomplete
                            [data]="parametersValue"
                            [searchKeyword]="keyword"
                            (selected)='selectParameter($event)'
                            [itemTemplate]="itemTemplate"
                            [notFoundTemplate]="notFoundTemplate"
                            formControlName = "parameter_id"
                            placeholder="Enter Parameter"
                            required>
                        </ng-autocomplete>
        
                        <ng-template #itemTemplate let-parameter>
                            <a [innerHTML]="parameter.name"></a>
                          </ng-template>
                        
                          <ng-template #notFoundTemplate let-notFound>
                            <div [innerHTML]="notFound"></div>
                          </ng-template>
                         
                          <app-form-error [controlObj]="param.get('parameter_id')" label="Parameter"></app-form-error>
                          <span (click)="delete(i)" class=" mt-2 ml-2 fa fa-times text-danger"></span>
                    </div>
                </div>
            </div>
           </div>
           
        </div>
    </div>


    <div class="row">
        <div class="col-md-12">
            <div class="pull-right">
                <button type="submit" class="btn btn-primary" [disabled]="buttonDisable" *ngIf="!chartDefinition.value.id">Submit
                </button>&nbsp;
                <button class="btn btn-danger " type="submit" [disabled]="buttonDisable" *ngIf="chartDefinition.value.id">Update
                </button>&nbsp;
                <button class="btn btn-primary " type="button" (click)="cancel()">Cancel</button>
            </div>
        </div>
    </div>

</form>
<!-- <button type="button" (click)="addParameter()">Add</button> -->