<div class="position-relative" *ngIf="formControl?.hasError">
    <div class="custom-tooltip" *ngIf="formControl?.hasError('required')">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        <span class="tooltiptext">{{label}} is required</span>
    </div>
    <div class="custom-tooltip" *ngIf="formControl?.hasError('minlength')">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        <span class="tooltiptext">{{label}} is Invalid</span>
    </div>
    <div class="custom-tooltip" *ngIf="formControl?.hasError('email')">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        <span class="tooltiptext">{{label}} is Invalid</span>
    </div>
</div>