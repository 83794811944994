import { Directive, ElementRef, TemplateRef, ViewContainerRef, Input  } from '@angular/core';
import { USER_TYPE_ADMIN, USER_TYPE_USER } from '@constants/global.constant';
import { AuthService } from '../../auth/auth.service';

@Directive({
  selector: '[hasPermission]'
})
export class PermissionDirective {
  
  private role = "";

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService
  ) { }

  @Input()
  set hasPermission(val: any) {
    this.role = val;
    this.updateView();
  }

  private updateView() {
    this.viewContainer.clear();
    let usertype: string = this.authService.getUserType();
    let userPermission = false;
    if(this.role[0] == 'A') {
        userPermission = true
    } else if(this.role[0].includes("U") && usertype == USER_TYPE_USER) {
        userPermission = true
    } else {
        userPermission = false;
    }
    let permissions = this.authService.getPermissions();
    if((userPermission && (permissions.includes(this.role[1]) || 
      this.role[1] == 'A'))) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
