import { Component, OnInit } from '@angular/core';
import { ParameterForm } from './parameter.form';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup,FormArray,FormControl,FormBuilder } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { adminApi } from '../../admin.api';
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'app-duplicate-parameter',
  templateUrl: './duplicate-parameter.component.html',
  styleUrls: ['./duplicate-parameter.component.scss']
})
export class DuplicateParameterComponent implements OnInit {

  parameter:any;
  parameterForm: FormGroup = ParameterForm;
  showLoader: boolean = false;
  submitted: boolean = false;
  buttonDisable:boolean = false;
  svids:any;
  chambers:any;
  receipes:any;
  steps:any;
  loops:any;
  keyword = 'name';

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.getSvid();
    this.getChamber();
    this.getEquipmentModel();
    this.parameter = this.parameterForm;
    this.route.paramMap.subscribe(params => {
      const parameterId = params.get('id');
      if(parameterId) {
        this.getParameter(parameterId);
      }
    })
  }


  svid:any;
  chamber:any;
  receipe:any;
  step:any;
  loop:any;
  equipments:any;
  
  getParameter(parameterId:any) {
    this.http.get(adminApi.getParameterById(parameterId)).subscribe((result:any) => {
      if(result.data[0].step == null) {
        this.svid = result.data[0].svid
        this.chamber = result.data[0].chamber
        this.receipe = result.data[0].receipe
        
        result.data[0].svid = this.svid;
        result.data[0].chamber_id = this.chamber;
        result.data[0].receipe_name = this.receipe;
        this.parameter.patchValue(result.data[0]);
        this.getStepsIsNull(result.data[0].chamber_id.id,result.data[0].receipe_id);
      }
      if(result.data[0].step != null) {
        this.svid = result.data[0].svid
        this.chamber = result.data[0].chamber
        this.receipe = result.data[0].receipe
        this.step = result.data[0].step

        result.data[0].svid = this.svid;
        result.data[0].chamber_id = this.chamber;
        result.data[0].receipe_name = this.receipe;
        result.data[0].steps = this.step;
        this.parameter.patchValue(result.data[0]);
        this.getLoopsIsNull(result.data[0].chamber_id.id,result.data[0].receipe_id,result.data[0].steps.id);
      }
      else {
      this.svid = result.data[0].svid
      this.chamber = result.data[0].chamber
      this.receipe = result.data[0].receipe
      this.step = result.data[0].step
      this.loop = result.data[0].loop
      
      result.data[0].svid = this.svid;
      result.data[0].chamber_id = this.chamber;
      result.data[0].receipe_name = this.receipe;
      result.data[0].steps = this.step;
      result.data[0].loop = this.loop;
      this.parameter.patchValue(result.data[0]);
      } 
    })
  }

  getSvid() {
    this.http.get(adminApi.svidListAll()).subscribe((result:any) => {
      this.svids = result.data;
    });
  }

  getChamber() {
    this.http.get(adminApi.chamberListAll()).subscribe((result:any) => {
      this.chambers = result.data;
    });
  }

  getReceipe(chamber_id:any) {
    this.http.get(adminApi.receipeListAll(chamber_id)).subscribe((result:any) => {
      if(result.data.length == 0 ) {
        this.getSteps(322,322);
        this.getLoops(242,32432,2322);
        this.parameter.get('receipe_name').setValue('');
        this.parameter.get('steps').setValue('');
        this.parameter.get('loop').setValue('');
        this.receipes = result.data;
      }else{
        this.parameter.get('receipe_name').setValue('');
        this.parameter.get('steps').setValue('');
        this.parameter.get('loop').setValue('');
        this.receipes = result.data;
      }
    }); 
  }

  getSteps(chamber_id:any,receipe_id:any) {
    this.http.get(adminApi.stepsListAll(chamber_id,receipe_id)).subscribe((result:any) => {
      this.steps = result.data;
    });
  }
  getStepsIsNull(chamber_id:any,receipe_id:any) {
    this.http.get(adminApi.stepsListAll(chamber_id,receipe_id)).subscribe((result:any) => {
      this.steps = result.data;
    });
  }

  getLoops(chamber_id:any,receipe_id:any,step_id:any) {
    this.http.get(adminApi.loopListAll(chamber_id,receipe_id,step_id)).subscribe((result:any) => {
      if(result.data.length == 0) {
        this.parameter.get('loop').setValue('');
        this.loops = result.data;
      }else{
        this.loops = result.data;
      }
    });
  }

  getLoopsIsNull(chamber_id:any,receipe_id:any,step_id:any) {
    this.http.get(adminApi.loopListAll(chamber_id,receipe_id,step_id)).subscribe((result:any) => {
      this.loops = result.data;
    });
  }

  getEquipmentModel() {
    this.http.get(adminApi.equipmentListAll()).subscribe((result:any) => {
      this.equipments = result.data;
    });
  }


  addParameter(){
    this.submitted = true;
    this.showLoader = true;
    this.buttonDisable = true;
    if(this.parameter.invalid) {
      this.buttonDisable = false; 
      return;
    }
    this.parameter.get('user_id').setValue(this.authService.getLoginId()); 
      this.http.post(adminApi.createParameter(),this.parameter.value).subscribe((result:any) => {
        this.parameter.reset();
        this.submitted = false;
        this.toastr.success("Parameter added successfully!");
        this.router.navigate(['/admin/parameter-list']);
      },(errorObj: any) => {
        if(errorObj.error.status == 'ERROR') {
          this.toastr.error("Parameter name already exist");
          this.submitted = false;
          this.showLoader = false;
          this.buttonDisable = false;
        }else {
        this.toastr.error(errorObj.error.errors.name[0]);
        this.submitted = false;
        this.showLoader = false;
        this.buttonDisable = false;
      }
        
       
      });
    
  }

  cancel() {
    this.parameter.reset();
    this.parameter.get('name').setValue('');
    this.parameter.get('description').setValue('');
    this.parameter.get('equipment_model_id').setValue('');
    this.parameter.get('svid').setValue('');
    this.parameter.get('chamber_id').setValue('');
    this.parameter.get('receipe_name').setValue('');
    this.parameter.get('steps').setValue('');
    this.parameter.get('loop').setValue('');
    this.router.navigate(['/admin/parameter-list']);
  }

  svid_id:any;
  selectSVID(svid:any) {
    this.svid_id = svid.id;   
  }

  chamberId:any;
  selectChamber(chamber_id:any) {
    this.chamberId = chamber_id.id;    
    this.getReceipe(this.chamberId);
  }

  receipe_id:any;
  selectReceipe(receipe_id:any) {
    this.parameter.get('steps').setValue('');
      this.parameter.get('loop').setValue('');
    this.receipe_id = receipe_id.id;
    this.chamber_id = this.parameter.get("chamber_id").value;
    this.getSteps(this.chamber_id.id,this.receipe_id)
    
  }

  stepId:any;
  selectSteps(steps:any) {
    this.stepId = steps.id;
    this.chamber_id = this.parameter.get("chamber_id").value;
    this.receipeId = this.parameter.get("receipe_name").value;
    this.getLoops(this.chamber_id.id,this.receipeId.id,this.stepId);
    
  }

  selectLoop(item:any) {
    console.log(item.id);
    
  }


  chamber_id:any;
  onChangeSearchReceipe(val: string) {
    this.chamber_id = this.parameter.get("chamber_id").value;
    this.getReceipe(this.chamber_id.id);
  }

  receipeId:any;
  onChangeSearchStep(val: string) {
    this.chamber_id = this.parameter.get("chamber_id").value;
    this.receipeId = this.parameter.get("receipe_name").value;
    this.getSteps(this.chamber_id.id,this.receipeId.id);
  }

  stepsId:any
  onChangeSearchLoop(val: string) {
    this.chamber_id = this.parameter.get("chamber_id").value;
    this.receipeId = this.parameter.get("receipe_name").value;
    this.stepsId = this.parameter.get("steps").value;
    this.getLoops(this.chamber_id.id,this.receipeId.id,this.stepsId.id);
  }

}
