import { Component, OnInit } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { LoginForm } from './login.form';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { ApiRoutes } from '@constants/api.constant';
import { environment } from '@env/environment';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  
  loginForm: FormGroup = LoginForm;
  showLoader: boolean = false;
  submitted: boolean = false;
  userDetails: any;
  
  get getControl(){
    return this.loginForm.controls;
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    this.redirectUser();
    localStorage.removeItem("token");
    localStorage.removeItem("usertype");
    localStorage.removeItem("id");
    localStorage.removeItem("refresh-token");
    localStorage.removeItem("expires-in");
  }

  login(): any {
    this.submitted = true;
    if (this.loginForm.invalid) return false;
    this.showLoader = true;
    let obj = {
      email : btoa(this.loginForm.value.email),
      password : btoa(this.loginForm.value.password),
      grant_type : environment.GRANT_TYPE,
      client_id : environment.CLIENT_ID,
      client_secret : environment.CLIENT_SECRET_KEY
    }
    this.authService.login(obj).subscribe((result) => {
      localStorage.setItem("token", result.access_token)
      localStorage.setItem('refresh-token', result.refresh_token);
      localStorage.setItem('expires-in', result.expires_in);
      this.authService.profileData().subscribe((result:any) => {
        this.showLoader = false;
        this.userDetails = result.data;
          if (this.userDetails.role_type == 'ADMIN') {
            localStorage.setItem('usertype', this.userDetails.role_type);
            localStorage.setItem('id', this.userDetails.id);
            this.toastr.success('Login successful');
            this.loginForm.reset();
            this.router.navigate(['/admin/overview']);
        } else {
          this.toastr.error("Please activate the link in email");
        }
      },(errorObj: any) => {
        this.toastr.error(errorObj.error.message);
        this.showLoader = false;
      });
      },(errorObj: any) => {
        this.toastr.error(errorObj.error.message);
        this.showLoader = false;
    });
  }
  
  redirectUser() {
    if (this.authService.isLoggedIn()) {
      this.router.navigate(['/admin/overview']);
    }
  }

}
