import {  FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";

let fb = new FormBuilder();

let searchForm: FormGroup = fb.group({
    search:[''],
})

export {
    searchForm
}
