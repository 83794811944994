import { Component, OnInit, Directive, HostListener, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { equipmentForm } from './equipmentForm';
import { searchForm } from '../../shared/forms/searchForm';
import { ToastrService } from 'ngx-toastr';
import { AuthService} from '../../auth/auth.service';
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { adminApi } from '../admin.api';

@Component({
  selector: 'app-equipment',
  templateUrl: './equipment.component.html',
  styleUrls: ['./equipment.component.css']
})
export class EquipmentComponent implements OnInit {

  equipmentsList:any = [];
  equipmentForm:any;
  searchForm:any;
  submitted: boolean = false;
  search:string = "";
  showLoader:boolean = false;
  closeResult:string = "";
  pagination:any;
      
  constructor(
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private authService: AuthService,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.searchForm = searchForm;
    this.getEquipments();
  }
  
  openModal(content:any) {
      this.equipmentForm = equipmentForm;
      this.equipmentForm.get('created_by').setValue(this.authService.getLoginId());
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  openEditModal(content:any, equipment:any) {
    this.equipmentForm = equipmentForm;
    this.equipmentForm.get('id').setValue(equipment.id);
    this.equipmentForm.get('name').setValue(equipment.name);
    this.equipmentForm.get('equipment_id').setValue(equipment.equipment_id);
    this.equipmentForm.get('ip_address').setValue(equipment.ip_address);
    this.equipmentForm.get('created_by').setValue(this.authService.getLoginId());        
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {

    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
  
  getEquipments() {
    this.showLoader = true;
    let pageUrl: string = "?search=" + this.searchForm.value.search;
    this.http.get(adminApi.equipmentList(pageUrl)).subscribe((result:any) => {
        this.equipmentsList = result.data;
        this.showLoader = false;
    })
  }

  save() {
    this.submitted = true;
    if(this.equipmentForm.invalid) {
      return;
    }
    if (this.equipmentForm.value.id) {  
        this.http.put(adminApi.updateEquipment(), this.equipmentForm.value).subscribe((result) => {
            this.toastr.success("Equipment updated successfully");
            this.submitted = false;
            this.equipmentForm.reset();
            this.refresh();
            let el = document.getElementById("cancel-modal");
            if(el) el.click();
        },(errorObj: any) => {
            var errors:any = Object.values(errorObj.error.errors).map((el:any) => el[0]);
            this.toastr.error(errors);
            this.toastr.error(errorObj.error.message);
            this.showLoader = false;
        });
    } else {
        this.http.post(adminApi.createEquipment(), this.equipmentForm.value).subscribe((result) => {
            this.toastr.success("Equipment added successfully");
            this.submitted = false;
            this.equipmentForm.reset();
            this.refresh();
            let el = document.getElementById("cancel-modal");
            if(el) el.click();
        },(errorObj: any) => {
            var errors:any = Object.values(errorObj.error.errors).map((el:any) => el[0]);
            this.toastr.error(errors);
            this.toastr.error(errorObj.error.message);
            this.showLoader = false;
        });
    }
  }

  deleteEquipment(id:any){
    if (confirm("Are you sure you want to delete?")) {
        this.http.delete(adminApi.deleteEquipment(id)).subscribe((result:any) => {
            this.toastr.success("Equipment deleted successfully");
            this.refresh();
        });
      }
  }

  refresh() {
    this.searchForm.get('search').setValue("");
    this.getEquipments();
  }

}
