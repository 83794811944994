import {  FormBuilder, FormGroup, FormArray, Validators } from "@angular/forms";

let fb = new FormBuilder();

let ParameterForm: FormGroup = fb.group({
    id: [''],
    name: ['', Validators.required],
    description: ['', Validators.required],
    equipment_model_id:['',Validators.required],
    svid: ['', Validators.required],
    chamber_id: ['', Validators.required],
    receipe_name: ['', Validators.required],
    steps: [''],
    loop: [''],
    user_id :[''],
})

export {
    ParameterForm
}
