import {  FormBuilder, FormGroup, FormArray, Validators } from "@angular/forms";

let fb = new FormBuilder();

let EmailForm: FormGroup = fb.group({
    id: [''],
    name: ['', Validators.required],
    email: ['',[Validators.required,Validators.email]],
    number:['',Validators.required],
    created_by :[''],
})

export {
    EmailForm
}
