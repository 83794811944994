<!-- Page Wrapper -->
<div id="wrapper">
    <app-sidebar></app-sidebar>

    <div id="content-wrapper" class="d-flex flex-column">
        <!-- Main Content -->
        <div id="content">
            <app-topbar></app-topbar>

            <div class="container">
                <!-- <breadcrumb #parent>
                    <ol class="breadcrumb">
                        <ng-template ngFor let-route [ngForOf]="parent.breadcrumbs">
                            <li *ngIf="!route.terminal" class="breadcrumb-item">
                                <a href="" [routerLink]="[route.url]">{{ route.displayName }}</a>
                            </li>
                            <li *ngIf="route.terminal" class="breadcrumb-item active" aria-current="page">
                                {{ route.displayName }}</li>
                        </ng-template>
                    </ol>
                </breadcrumb> -->
            </div>

            <div class="container-fluid">
                <router-outlet></router-outlet>
            </div>
        </div>

        <app-footer></app-footer>
    </div>


</div>