import { Component, OnInit } from '@angular/core';
import { EmailForm } from './email.form';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup,FormArray,FormControl,FormBuilder } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { adminApi } from '../../admin.api';
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'app-create-email',
  templateUrl: './create-email.component.html',
  styleUrls: ['./create-email.component.scss']
})
export class CreateEmailComponent implements OnInit {

  email:any;
  emailForm: FormGroup = EmailForm;
  showLoader: boolean = false;
  submitted: boolean = false;
  buttonDisable:boolean = false;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {

    this.email = this.emailForm;
    this.route.paramMap.subscribe(params => {
      const emailId = params.get('id');
      if(emailId) {
        this.getEmail(emailId);
      }
    })
  }
  

  getEmail(email_id:any) {
    this.http.get(adminApi.getEmailById(email_id)).subscribe((result:any) => {
      this.email.patchValue(result.data[0]);
    })
  } 


  addParameter(){
    this.submitted = true;
    this.showLoader = true;
    this.buttonDisable = true;
    if(this.email.invalid) {
      this.buttonDisable = false; 
      return;
    }
    this.email.get('created_by').setValue(this.authService.getLoginId());
    if(this.email.value.id) {
      this.http.put(adminApi.updateEmail(),this.email.value).subscribe((result:any) => {
        this.email.reset();
        this.submitted = false;
        this.toastr.success("Email updated successfully!");
        this.router.navigate(['/admin/email-list']);
      },(errorObj: any) => {
        // var errors:any = Object.values(errorObj.error.errors).map((el:any) => el[0]);
        this.toastr.error(errorObj.error.message);
        // this.toastr.error(errors);
        this.submitted = false;
        this.showLoader = false;
        this.buttonDisable = false;
      });
    } else {
      this.http.post(adminApi.createEmail(),this.email.value).subscribe((result:any) => {
        this.email.reset();
        this.submitted = false;
        this.toastr.success("Email added successfully!");
        this.router.navigate(['/admin/email-list']);
      },(errorObj: any) => {
        // var errors:any = Object.values(errorObj.error.errors).map((el:any) => el[0]);
        this.toastr.error(errorObj.error.message);
        // this.toastr.error(errors);
        this.submitted = false;
        this.showLoader = false;
        this.buttonDisable = false;
      });
    }
  }

  cancel() {
    // this.parameter.reset();
    this.email.get('name').setValue('');
    this.email.get('email').setValue('');
    this.email.get('number').setValue('');
    this.router.navigate(['/admin/email-list']);
  }


  numberOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 43 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
   return true;
  }

}
