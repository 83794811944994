import {  FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";

let fb = new FormBuilder();

let equipmentForm: FormGroup = fb.group({
    id:[''],
    name:['',Validators.required],
    equipment_id:['',Validators.required],
    ip_address:['',Validators.required],
    created_by:['']
})

export {
    equipmentForm
}
