import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  sideBarToggled: boolean = false;
  userType?:string;

  constructor(
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.userType = this.authService.getUserType();
  }

  sidebarToggle() {
    this.sideBarToggled = !this.sideBarToggled;
  }
}
