import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup,FormArray,FormControl,FormBuilder } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { adminApi } from '../admin.api';
import { AuthService } from '../../auth/auth.service';
import { SpcChartForm } from './spc-chart.form';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import {DatePipe} from '@angular/common';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-spc-chart',
  templateUrl: './spc-chart.component.html',
  styleUrls: ['./spc-chart.component.scss']
})
export class SpcChartComponent implements OnInit {

  equipments:any;
  spcChart:any;
  spcChartForm: FormGroup = SpcChartForm;
  submitted: boolean = false;
  buttonDisable:boolean = false;
  showChart:boolean = false;
  svids:any;
  dropdownSettings1 = {};
  dropdownSettings2 = {};
  ShowFilter = false;
  disabled = false;
  selectedItems = [];
  fromDate: any;
  toDate: any;
  pipe: any = new DatePipe('en-US');
  lineChartData: ChartDataSets[] = [];
  lineChartLabels: Label[] = [];
  lineChartColors: Color[] = [];
  lineChartLegend: boolean = true;
  lineChartPlugins: any = [];
  lineChartType: any = 'line';  
  lineChartOptions: any;  
  
  constructor(
    private router: Router,
    private toastr: ToastrService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private authService: AuthService,    
  ) { }

  ngOnInit(): void {
    this.spcChart = this.spcChartForm;
    this.getEquipmentModel();
    this.getSvid();
    this.dropdownSettings1 = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 8,
      allowSearchFilter: this.ShowFilter
    };
    this.dropdownSettings2 = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 8,
      allowSearchFilter: this.ShowFilter
    };
  }

  dateSearch() {
    
  }

//  parameter:any;
  parameters:any;
  getParammeters(svid: any) {
     this.http.get(adminApi.getParametersBySvid(svid)).subscribe((result:any) => {
//      result.data.forEach((obj:any) => {
          this.parameters = result.data;  
//      })
    })
  }

  selectParameter() {

  }

  getEquipmentModel() {
    this.http.get(adminApi.equipmentListAll()).subscribe((result:any) => {
      this.equipments = result.data;
    });
  }

  getSvid() {
    this.http.get(adminApi.svidListAll()).subscribe((result:any) => {
      this.svids = result.data;
    });
  }

  addSpcChart() {
    this.submitted = true;
    if(this.spcChart.invalid) {
      return;
    }
//    let parameter_id : any = [];
//    this.spcChart.value.parameter.forEach(function(value:any, key:any) {
//      parameter_id.push(value.id);
//    });
    let obj = {
      parameter_id : this.spcChart.value.parameter,
      start_date : this.spcChart.value.start_date,
      start_time : this.spcChart.value.start_time,
      end_date : this.spcChart.value.end_date,
      end_time : this.spcChart.value.end_time,
    };
    this.http.post(adminApi.getChartDetails(), obj).subscribe((result:any) => {
      this.plotChart(result.data);
    },(errorObj: any) => {
      this.toastr.error(errorObj.error.message);
    });
  }

  plotChart(result:any) {
    this.showChart = true;
    
    this.lineChartData = [
      { data: result.mean, label: 'Mean', xAxisID: 'x-axis-2', fill:false, borderColor: 'red', backgroundColor: 'red', borderWidth: 2 },
      { data: result.usl, label: 'USL', fill:false, borderColor: 'blue', backgroundColor: 'blue', borderWidth: 3 },
      { data: result.ucl, label: 'UCL', fill:false, borderColor: 'orange', backgroundColor: 'orange', borderWidth: 3 },
      { data: result.center_line, label: 'Center Line', fill:false, borderColor: 'green', backgroundColor: 'green', borderWidth: 3 },
      { data: result.lsl, label: 'LSL', fill:false, borderColor: 'yellow', backgroundColor: 'yellow', borderWidth: 3 },
      { data: result.lcl, label: 'LCL', fill:false, borderColor: 'pink', backgroundColor: 'pink', borderWidth: 3 },
    ];

    this.lineChartLabels = result.date_time;

    this.lineChartOptions = {
      responsive: true,
      title: {
        display: true,
        text: 'SPC Chart - Mean Value'
      },
      scales: {
        xAxes: [{
          gridLines: {
          display: false,
        }
        }, {
          id: 'x-axis-2',
          type: 'linear',
          position: 'bottom',
          display: false,
        }],
        yAxes: [{
          beginAtZero: true,
          gridLines: {
            display: false,
          },
        }]
      }
    };

  this.lineChartColors = [
    {
      borderColor: 'red',
      backgroundColor: 'rgb(255, 99, 132)',
    },
  ];

  }
}
