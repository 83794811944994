import {  FormBuilder, FormGroup, FormArray, Validators } from "@angular/forms";

let fb = new FormBuilder();

let SvidChamberForm: FormGroup = fb.group({
    id: [''],
    number: ['', Validators.required],
    svid: ['', Validators.required],
    chamber_id: ['', Validators.required],
})

export {
    SvidChamberForm
}
