import { environment } from '../../environments/environment';

const adminApi = {
    userList   : (pageUrl:string) => environment.APP_API_URL + "/user" + pageUrl,
    createUser : () => environment.APP_API_URL + "/user",
    updateUser : () => environment.APP_API_URL + "/user",
    deleteUser : (id:number) => environment.APP_API_URL + "/user/" + id,
    userById   : (id:number) => environment.APP_API_URL + "/user/" + id,
    
    departmentByStatus : (status:string) => environment.APP_API_URL + "/department/find-by-status/" + status,
    departmentList : (pageUrl:string) => environment.APP_API_URL + "/department" + pageUrl,
    createDepartment : () => environment.APP_API_URL + "/department",
    updateDepartment : () => environment.APP_API_URL + "/department",
    deleteDepartment : (id:number) => environment.APP_API_URL + "/department/" + id ,
    departmentById   : (id:number) => environment.APP_API_URL + "/department/" + id,
    
    designationByStatus : (status:string) => environment.APP_API_URL + "/designation/find-by-status/" + status,
    designationList     : (pageUrl:string) => environment.APP_API_URL + "/designation" + pageUrl,
    createDesignation   : () => environment.APP_API_URL + "/designation",
    updateDesignation   : () => environment.APP_API_URL + "/designation",
    deleteDesignation   : (id:number) => environment.APP_API_URL + "/designation/" + id ,
    designationById     : (id:number) => environment.APP_API_URL + "/designation/" + id,
    
    equipmentList   : (pageUrl:string) => environment.APP_API_URL + "/equipment" + pageUrl,
    createEquipment : () => environment.APP_API_URL + "/equipment",
    updateEquipment : () => environment.APP_API_URL + "/equipment",
    deleteEquipment : (id:number) => environment.APP_API_URL + "/equipment/" + id ,
    equipmentById   : (id:number) => environment.APP_API_URL + "/equipment/" + id,
    
    menuListAll : () => environment.APP_API_URL + "/menu/find/all",


    createParameter : () => environment.APP_API_URL + "/parameter",
    updateParameter : () => environment.APP_API_URL + "/parameter",
    parameterList   : (pageUrl:string) => environment.APP_API_URL + "/parameter" +pageUrl,
    deleteParameter : (id:number) => environment.APP_API_URL + "/parameter/" + id,
    getParameterById: (id:number) => environment.APP_API_URL + "/parameter/" + id,
    equipmentListAll : () =>  environment.APP_API_URL + "/equipment",

    svidListAll    : () => environment.APP_API_URL + "/svid/find/all",
    chamberListAll : () => environment.APP_API_URL + "/chamber/find/all",
    receipeListAll : (chamber_id:any) => environment.APP_API_URL + "/receipe/find/all/"+ chamber_id ,
    stepsListAll   : (chamber_id:any,receipe_id:any) => environment.APP_API_URL + "/step/find/all/" + chamber_id +"/" +receipe_id,
    loopListAll    : (chamber_id:any,receipe_id:any,step_id:any) => environment.APP_API_URL + "/loop/find/all/"  + chamber_id +"/" +receipe_id + "/"+step_id,
    
    //SVID,CHAMBER,NUMBER
    createSvidChamber : () => environment.APP_API_URL + "/svid-chamber",
    

    //Chart definition
    getParameterByUserId : (user_id:any) => environment.APP_API_URL + "/parameter-by-user-id/" +user_id,
    createChart: () => environment.APP_API_URL + "/chart",
    updateChart: () => environment.APP_API_URL + "/chart",
    chartList   : (pageUrl:string) => environment.APP_API_URL + "/chart" +pageUrl,
    getChartById : (chart_id:any) => environment.APP_API_URL + "/chart/" + chart_id,
    updateChartDefinitionParameter: () => environment.APP_API_URL + "/update-chart-parameter",
    deleteChartDefinition : (id:any) => environment.APP_API_URL + "/chart/" + id,
    //SPC CHART
    getParameterBySvid :() => environment.APP_API_URL + "/parameter-by-svid",
    getParametersBySvid :(id:any) => environment.APP_API_URL + "/parameter/by-svid/"+id,
    getAllChart  : () => environment.APP_API_URL + "/get-all-chart",

    emailList   : (pageUrl:string) => environment.APP_API_URL + "/email" +pageUrl,
    createEmail: () => environment.APP_API_URL + "/email",
    updateEmail: () => environment.APP_API_URL + "/email",
    deleteEmail : (id:any) => environment.APP_API_URL + "/email/" + id,
    getEmailById : (email_id:any) => environment.APP_API_URL + "/email/" + email_id,
    getAllEmails : () => environment.APP_API_URL + "/email",
    
    getChartDetails : () => environment.APP_API_URL + "/spc-chart",

    //Alarm Setting
    createAlarm : () => environment.APP_API_URL + "/alarm-setting",
    updateAlarm : () => environment.APP_API_URL + "/alarm-setting",
    deleteAlarm : (id:any) => environment.APP_API_URL + "/alarm-setting/" + id,
    getAllAlarm : (pageUrl:any)  => environment.APP_API_URL + "/alarm-setting" +pageUrl,
    getAlarmById : (alarm_id:any) => environment.APP_API_URL + "/alarm-setting/" + alarm_id,
    addAlarmEmail : () => environment.APP_API_URL + "/add-alarm-email",
    addAlarmChart : () => environment.APP_API_URL + "/add-alarm-chart",
}

export {
    adminApi
}