<nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

  <!-- Sidebar Toggle (Topbar) -->
  <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
      <i class="fa fa-bars"></i>
  </button>

  <!-- Topbar Search -->
  <form
      class="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
      <div class="input-group">
          <input type="text" class="form-control bg-light border-0 small" placeholder="Search for..."
              aria-label="Search" aria-describedby="basic-addon2">
          <div class="input-group-append">
              <button class="btn btn-primary" type="button">
                  <i class="fas fa-search fa-sm"></i>
              </button>
          </div>
      </div>
  </form>

  <!-- <div class="btn-group" dropdown>
    <button id="button-basic" dropdownToggle type="button" class="btn btn-light dropdown-toggle"
            aria-controls="dropdown-basic"> 
            <img src="assets/images/en.png" alt="">
            <span class="caret"></span>
    </button>
    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu d-inline-block"
        role="menu" aria-labelledby="button-basic">
      <li role="menuitem">
        <a class="dropdown-item" href="javascript:void(0)">
          <img src="assets/images/en.png" alt="">
        </a>
      </li>
      <li role="menuitem">
        <a class="dropdown-item" href="javascript:void(0)">
          <img src="assets/images/en.png" alt="">
        </a>
      </li>
    </ul>
  </div> -->

  <!-- Topbar Navbar -->
  <ul class="navbar-nav ml-auto">

      <!-- Nav Item - Search Dropdown (Visible Only XS) -->
      <li class="nav-item dropdown no-arrow d-sm-none">
          <a class="nav-link dropdown-toggle" href="javascript:void(0)" id="searchDropdown" role="button"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="fas fa-search fa-fw"></i>
          </a>
          <!-- Dropdown - Messages -->
          <div class="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
              aria-labelledby="searchDropdown">
              <form class="form-inline mr-auto w-100 navbar-search">
                  <div class="input-group">
                      <input type="text" class="form-control bg-light border-0 small"
                          placeholder="Search for..." aria-label="Search"
                          aria-describedby="basic-addon2">
                      <div class="input-group-append">
                          <button class="btn btn-primary" type="button">
                              <i class="fas fa-search fa-sm"></i>
                          </button>
                      </div>
                  </div>
              </form>
          </div>
      </li>

      <!-- Nav Item - Alerts -->
      <li class="nav-item dropdown no-arrow mx-1">
          <a class="nav-link dropdown-toggle" href="javascript:void(0)" id="alertsDropdown" role="button"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="fas fa-bell fa-fw"></i>
              <!-- Counter - Alerts -->
              <span class="badge badge-danger badge-counter">3+</span>
          </a>
          <!-- Dropdown - Alerts -->
          <div class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="alertsDropdown">
              <h6 class="dropdown-header">
                  Alerts Center
              </h6>
              <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                  <div class="mr-3">
                      <div class="icon-circle bg-primary">
                          <i class="fas fa-file-alt text-white"></i>
                      </div>
                  </div>
                  <div>
                      <div class="small text-gray-500">December 12, 2019</div>
                      <span class="font-weight-bold">A new monthly report is ready to download!</span>
                  </div>
              </a>
              <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                  <div class="mr-3">
                      <div class="icon-circle bg-success">
                          <i class="fas fa-donate text-white"></i>
                      </div>
                  </div>
                  <div>
                      <div class="small text-gray-500">December 7, 2019</div>
                      $290.29 has been deposited into your account!
                  </div>
              </a>
              <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                  <div class="mr-3">
                      <div class="icon-circle bg-warning">
                          <i class="fas fa-exclamation-triangle text-white"></i>
                      </div>
                  </div>
                  <div>
                      <div class="small text-gray-500">December 2, 2019</div>
                      Spending Alert: We've noticed unusually high spending for your account.
                  </div>
              </a>
              <a class="dropdown-item text-center small text-gray-500" href="javascript:void(0)">Show All Alerts</a>
          </div>
      </li>

      <!-- Nav Item - Messages -->
      <li class="nav-item dropdown no-arrow mx-1">
          <a class="nav-link dropdown-toggle" href="javascript:void(0)" id="messagesDropdown" role="button"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="fas fa-envelope fa-fw"></i>
              <!-- Counter - Messages -->
              <span class="badge badge-danger badge-counter">7</span>
          </a>
          <!-- Dropdown - Messages -->
          <div class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="messagesDropdown">
              <h6 class="dropdown-header">
                  Message Center
              </h6>
              <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                  <div class="dropdown-list-image mr-3">
                      <img class="rounded-circle" src="assets/images/profile.svg"
                          alt="...">
                      <div class="status-indicator bg-success"></div>
                  </div>
                  <div class="font-weight-bold">
                      <div class="text-truncate">Hi there! I am wondering if you can help me with a
                          problem I've been having.</div>
                      <div class="small text-gray-500">Emily Fowler · 58m</div>
                  </div>
              </a>
              <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                  <div class="dropdown-list-image mr-3">
                      <img class="rounded-circle" src="assets/images/profile.svg"
                          alt="...">
                      <div class="status-indicator"></div>
                  </div>
                  <div>
                      <div class="text-truncate">I have the photos that you ordered last month, how
                          would you like them sent to you?</div>
                      <div class="small text-gray-500">Jae Chun · 1d</div>
                  </div>
              </a>
              <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                  <div class="dropdown-list-image mr-3">
                      <img class="rounded-circle" src="assets/images/profile.svg"
                          alt="...">
                      <div class="status-indicator bg-warning"></div>
                  </div>
                  <div>
                      <div class="text-truncate">Last month's report looks great, I am very happy with
                          the progress so far, keep up the good work!</div>
                      <div class="small text-gray-500">Morgan Alvarez · 2d</div>
                  </div>
              </a>
              <a class="dropdown-item text-center small text-gray-500" href="javascript:void(0)">Read More Messages</a>
          </div>
      </li>

      <div class="topbar-divider d-none d-sm-block"></div>

      <!-- Nav Item - User Information -->
      <li class="nav-item dropdown no-arrow">
          <a class="nav-link dropdown-toggle" href="javascript:void(0)" id="userDropdown" role="button"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span class="mr-2 d-none d-lg-inline text-gray-600 small">Douglas McGee</span>
              <img class="img-profile rounded-circle"
                  src="assets/images/profile.svg">
          </a>
          <!-- Dropdown - User Information -->
          <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="userDropdown">
              <a class="dropdown-item" href="javascript:void(0)">
                  <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                  Profile
              </a>
              <a class="dropdown-item" href="javascript:void(0)">
                  <i class="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                  Settings
              </a>
              <a class="dropdown-item" href="javascript:void(0)">
                  <i class="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                  Activity Log
              </a>
              <!-- <div class="dropdown-divider"></div> -->
              <a class="dropdown-item" href="javascript:void(0)" (click)="logout()">
                  <i class="fas fa-sign-out-alt fa-fw fa-fw mr-2 text-gray-400"></i>
                  Logout
              </a>
          </div>
      </li>

  </ul>

</nav>