import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginLayoutComponent } from './login-layout/login-layout.component';
import { RouterModule } from '@angular/router';
import { DefaultLayoutComponent } from './default-layout/default-layout.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { TopbarComponent } from './topbar/topbar.component';
import { FooterComponent } from './footer/footer.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
// import { BreadcrumbModule } from 'angular-crumbs';

@NgModule({
  declarations: [
    LoginLayoutComponent,
    DefaultLayoutComponent,
    SidebarComponent,
    AdminLayoutComponent,
    TopbarComponent,
    FooterComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    // BreadcrumbModule
  ],
  exports: [
    RouterModule,
    LoginLayoutComponent,
    DefaultLayoutComponent,
    AdminLayoutComponent,
  ]
})
export class LayoutsModule { }
