import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService} from '../../../auth/auth.service';
import { adminApi } from '../../admin.api';
import { searchForm } from '../../../shared/forms/searchForm';

@Component({
  selector: 'app-email-list',
  templateUrl: './email-list.component.html',
  styleUrls: ['./email-list.component.scss']
})
export class EmailListComponent implements OnInit {

  showLoader : boolean = false;
  search : any;
  pagination:any;
  emailsList:any = [];
  count:any;
  searchForm:any;

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getEmail();
    this.searchForm = searchForm;
  }

  refresh() {
    this.searchForm.get('search').setValue("");
    this.getEmail();
  }

  getSearchList() { 
    this.getEmail();
  }

  getEmail() {
    this.showLoader = true;
    this.search = searchForm;
    let pageUrl = "?search=" + this.search.value.search; 
      this.http.get(adminApi.emailList(pageUrl)).subscribe((result:any) => {
          this.emailsList = result.data;
          this.count = this.emailsList.length;
          this.showLoader = false;
      });        
  }
  
  delete(id:number) {
    if (confirm("Are you sure you want to delete?")) {
      this.http.delete(adminApi.deleteEmail(id)).subscribe((result:any) => {
        this.toastr.success("Email deleted successfully");
        this.refresh();
      },(errorObj: any) => {
        this.toastr.error(errorObj.error.message);
      }
      );
    }
  }

}
