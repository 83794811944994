import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss']
})
export class FormErrorComponent implements OnInit {

  @Input('controlObj') formControl!: AbstractControl | null;

  @Input('label') label!: string;

  constructor() {}

  ngOnInit() {

  }

}
