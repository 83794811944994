import { Component, OnInit,ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { adminApi } from '../../admin.api';
import { searchForm } from '../../../shared/forms/searchForm';
import {FormGroup, FormBuilder, FormControl, Validators,FormArray} from '@angular/forms';
import { AuthService } from '../../../auth/auth.service';
import { AlarmEmailForm } from './alarm-email.form';
import { AlarmChartForm } from './alarm-chart.form';

@Component({
  selector: 'app-alarm-list',
  templateUrl: './alarm-list.component.html',
  styleUrls: ['./alarm-list.component.scss']
})
export class AlarmListComponent implements OnInit {
  @ViewChild('closebutton') closebutton:any;
  @ViewChild('closeChartButton') closeChartButton:any;

  showLoader : boolean = false;
  alarmLists : any;
  pagination:any;
  count:any;
  searchForm:any;
  search:any;
  charts:any;
  emails:any;
  alarmEmail:any;
  alarmEmailForm: FormGroup = AlarmEmailForm;
  alarmChart:any;
  alarmChartForm: FormGroup = AlarmChartForm;

  submitted: boolean = false;
  addMoreButtonVisible : boolean = true;
  emailList:any;
  chartsList:any;
  buttonDisable:boolean = false;

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.searchForm = searchForm;
    this.alarmEmail = this.alarmEmailForm;
    this.alarmChart = this.alarmChartForm;
    this.getAlarmList();
  }

  getSearchList() { 
    this.getAlarmList();
  }

  refresh() {
    this.searchForm.get('search').setValue("");
    this.getAlarmList();
  }

  getAlarmList() {
    this.search = searchForm;
    this.showLoader = true;
    let pageUrl = "?search=" + this.search.value.search; 
      this.http.get(adminApi.getAllAlarm(pageUrl)).subscribe((result:any) => {
          this.alarmLists = result.data;
          this.count = this.alarmLists.length;
          this.showLoader = false;
      }); 
  }

  viewChart(alarm:any) {
    this.charts = alarm.alarm_chart;
  }

  viewEmail(alarm:any) {
    this.emails = alarm.alarm_email;
  }

  delete(id:any) {
    if (confirm("Are you sure you want to delete?")) {
      this.http.delete(adminApi.deleteAlarm(id)).subscribe((result:any) => {
        this.toastr.success("Alarm deleted successfully");
        this.refresh();
      });
    }
  }


  getAllEmails() {
    this.http.get(adminApi.getAllEmails()).subscribe((result:any) => {
      this.emailList = result.data;
    })
  }

  getAllChart() {
    this.http.get(adminApi.getAllChart()).subscribe((result:any) => {
      this.chartsList = result.data
    })
  }

  alarm_id:any;
  openChartModal(alarmValue:any) {
    (this.alarmChart.get("charts") as FormArray)['controls'].splice(0);
    this.getAllChart();
    this.alarm_id = alarmValue.id;
    this.addMoreButtonVisible = true;
  }

  openEmailModal(alarmValue:any) {
    (this.alarmEmail.get("emails") as FormArray)['controls'].splice(0);
    this.getAllEmails();
    this.alarm_id = alarmValue.id;
    this.addMoreButtonVisible = true;
  }

  closeChartModal() {
    this.addMoreButtonVisible = true;
    this.submitted = false;
  }
  closeEmailModal() {
    this.addMoreButtonVisible = true;
    this.submitted = false;
  }
  





  get email() : FormArray {
    return this.alarmEmail.get('emails') as FormArray;
  }

  newEmail(): FormGroup {
    return this.fb.group({
      email_id: ['',Validators.required]
    })
 }

  addEmail() {
    this.email.push(this.newEmail());
  }

  deleteEmail(index:any) {
    this.email.removeAt(index);
  }

  AddMoreEmail() {
    this.addEmail();
    this.addMoreButtonVisible = false;
    
  }
  ///////////////////////////////////End of Email////////////////////////////////////////

  //////////////////////////////////Add Chart Array///////////////////////////////////////

  get chart() : FormArray {
    return this.alarmChart.get('charts') as FormArray;
  }

  newChart(): FormGroup {
    return this.fb.group({
      chart_id: ['',Validators.required]
    })
 }

  addChart() {
    this.chart.push(this.newChart());
  }

  deleteChart(index:any) {
    this.chart.removeAt(index);
  }

  AddMoreChart() {
    this.addChart();
    this.addMoreButtonVisible = false;
  }


  saveEmail() {
    this.submitted = true;
    if(this.alarmEmail.invalid) {
      this.buttonDisable = false; 
      return;
    }
    this.alarmEmail.get('id').setValue(this.alarm_id)
    this.alarmEmail.get('created_by').setValue(this.authService.getLoginId()); 
    this.http.post(adminApi.addAlarmEmail(),this.alarmEmail.value).subscribe((result:any) => {
      this.toastr.success('Email Added Successfully');
      this.getAlarmList();
      this.closebutton.nativeElement.click();
      this.addMoreButtonVisible = true;
      },(errorObj: any) => {      
        this.toastr.error(errorObj.error.message);
      })
        console.log(this.alarmEmail.value);
     
  }

  saveChart() {
    this.submitted = true;
    if(this.alarmChart.invalid) {
      this.buttonDisable = false; 
      return;
    }
    this.alarmChart.get('id').setValue(this.alarm_id)
    this.alarmChart.get('created_by').setValue(this.authService.getLoginId()); 
    this.http.post(adminApi.addAlarmChart(),this.alarmChart.value).subscribe((result:any) => {
      this.toastr.success('Chart Added Successfully');
      this.getAlarmList();
      this.closeChartButton.nativeElement.click();
      this.addMoreButtonVisible = true;
      },(errorObj: any) => {      
        this.toastr.error(errorObj.error.message);
      })
        console.log(this.alarmEmail.value);
  }

  


}
