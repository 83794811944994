import {  FormBuilder, FormGroup, Validators } from "@angular/forms";

let fb = new FormBuilder();

let LoginForm: FormGroup = fb.group({
    email: ['', Validators.required],
    password: ['', Validators.required],
    syncFlag: [false],
    remember: []
   
})
export {
  LoginForm
}
