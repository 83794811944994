<div class="row justify-content-sm-center mt-5">
  <div class="col-sm-6 col-md-5">
    <div class="text-center p-5 shadow">
      <h3 class="pb-2">
        Sign in to continue
      </h3>
      <div>
        <img class="rounded-circle" src="">
        <!-- <h4 class="text-center">Video Survey</h4> -->
        <form [formGroup]="loginForm" class="text-left" [ngClass]="{'form-validated': submitted}" (ngSubmit)="login()">
          <div class="form-group">
            <label for="form-email">Email</label>
            <input type="text" formControlName="email" class="form-control mb-2" placeholder="Email">
            <app-form-error [controlObj]="loginForm.get('email')" label="Email"></app-form-error>
          </div>
          <div class="form-group">
            <label for="form-password">Password</label>
            <input type="password" formControlName="password" class="form-control mb-2" placeholder="Password">
            <app-form-error [controlObj]="loginForm.get('password')" label="Password"></app-form-error>
          </div>
          <button class="btn btn-lg btn-primary btn-block mb-1" type="submit">Sign in
            <i class="fa fa-spinner fa-spin" *ngIf="showLoader"></i>
          </button>
          <a [routerLink]="['/forgot-password']" class="float-left mt-3">Forgot Password</a>

        </form>
      </div>
    </div>
  </div>
</div>