import { Component, OnInit } from '@angular/core';
import { SvidChamberForm } from './svidchamber.form';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup,FormArray,FormControl,FormBuilder } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { adminApi } from '../admin.api';

@Component({
  selector: 'app-svid-champer',
  templateUrl: './svid-champer.component.html',
  styleUrls: ['./svid-champer.component.scss']
})
export class SvidChamperComponent implements OnInit {

  svidChamber:any;
  svidChamberForm: FormGroup = SvidChamberForm;
  showLoader: boolean = false;
  submitted: boolean = false;
  buttonDisable:boolean = false;
  svids:any;
  chambers:any;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.getSvid();
    this.getChamber();
    this.svidChamber = this.svidChamberForm;
  }


  getSvid() {
    this.http.get(adminApi.svidListAll()).subscribe((result:any) => {
      this.svids = result.data;
    });
  }

  getChamber() {
    this.http.get(adminApi.chamberListAll()).subscribe((result:any) => {
      this.chambers = result.data;
    });
  }

  addSvidChamber(){
    this.submitted = true;
    this.showLoader = true;
    this.buttonDisable = true;
    if(this.svidChamber.invalid) {
      this.buttonDisable = false; 
      return;
    }
      this.http.post(adminApi.createSvidChamber(),this.svidChamber.value).subscribe((result:any) => {
        this.svidChamber.reset();
        this.submitted = false;
        this.toastr.success("SVID Chamber Number added successfully!");
        // this.router.navigate(['/admin/parameter-list']);
        this.buttonDisable = false;
      },(errorObj: any) => {
        var errors:any = Object.values(errorObj.error.errors).map((el:any) => el[0]);
        this.toastr.error(errorObj.error.message);
        this.toastr.error(errors);
        this.submitted = false;
        this.showLoader = false;
        this.buttonDisable = false;
      });
    
  }

  cancel() {

  }

}
