<div class="row mb-1">
    <div class="col-md-6 mb-2">
        <div class="pull-left count-widget m-r-sm mt-2">
            <button type="button" data-toggle="tooltip" data-placement="bottom" title="Total"
                    class="btn btn-danger btn-sm count-widget mb-2">
                <i class="fa fa-folder-open "></i>
                Total
               <span class="badge badge-info rounded-circle ml-1">{{equipmentsList.length}}</span>
            </button>
            <button class="btn btn-lg btn-outline-primary" (click)="openModal(equipmentModal)" title="Add"
                    class="btn btn-danger btn-sm common-btn ml-2 mb-2">
                <i class="fa fa-plus-circle"></i>
                Add
            </button>
            <button type="button" data-toggle="tooltip" data-placement="bottom" title="Refresh" (click)="refresh()"
                    class="btn btn-danger btn-sm common-btn ml-2 mb-2">
                <i class="fa fa-refresh"></i>
            </button>
        </div>
    </div>
    <div class="col-md-6 mb-2">
        <div class="pull-right quick-search-wrapper mb-2">
            <form [formGroup]="searchForm" title="Search" >
                <div class="float-right quick-search-wrapper">
                    <div class="form-inline">
                        <input class="form-control" formControlName="search" name="search"
                               type="search" placeholder="Quick Search" aria-label="quick-search">
                        <button type="button" data-toggle="tooltip" data-placement="bottom" title="Search"
                                class="btn btn-primary" (click)="getEquipments()">
                            <i class="fa fa-search"></i>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="clearfix"></div>
<!-- <div class="card"> -->
<div class="table-responsive-sm table-responsive-md table-responsive-lg table-responsive-xl">
    <table class="table table-bordered table-md">
        <thead>
            <tr>
                <th>#</th>
                <th>Equipment Name</th>
                <th>Equipment Id</th>
                <th>IP Address</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngIf="showLoader">
                <td colspan="3" class="text-center">
                    <i class="fa fa-spinner fa-2x fa-spin mx-3" aria-hidden="true"></i>
                </td>
            </tr>
            <tr *ngIf="equipmentsList && equipmentsList.length == 0 && !showLoader">
                <td colspan="5" class="text-center"> No Records Found</td>
            </tr>
            <tr *ngFor="let equipment of equipmentsList | paginate: { itemsPerPage: 10, currentPage: pagination } let i=index;">
                <!--<td>{{ (((paginationObject.currentPage - 1) * paginationObject.limit) + 1 ) + i}}</td>-->
                <td>{{i + 1}}</td>
                <td>{{equipment.name}}</td>
                <td>{{equipment.equipment_id}}</td>
                <td>{{equipment.ip_address}}</td>
                <td>
                    <button class="btn btn-link btn-sm btn-icon-only" (click)="openEditModal(equipmentModal, equipment)" title="Edit">
                            <i class="fa fa-edit" aria-hidden="true"></i>
                    </button>
                    <span (click)="deleteEquipment(equipment.id)" class="mt-2 ml-2 fa fa-times text-danger"></span>
                </td>
            </tr>
        </tbody>
    </table>
    <!-- </div> -->
</div>

<pagination-controls (pageChange)="pagination = $event"></pagination-controls>

<!--<app-pagination-content (getPagination)='equipmentsList(paginationObject)' [paginationObject]="paginationObject">
</app-pagination-content> -->

<ng-template #equipmentModal let-modal>

    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalScrollableTitle">Equipment</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="equipmentForm" class="text-left" [ngClass]="{'form-validated': submitted}">
                <div class="form-group row">
                    <label class="col-sm-6 col-form-label">Equipment Name</label>
                    <div class="col-sm-6">
                        <input type="text" formControlName="name" class="form-control" placeholder="Enter Equipment Name">
                        <app-form-error [controlObj]="equipmentForm.get('name')" label="name">
                        </app-form-error>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-6 col-form-label">Equipment Id</label>
                    <div class="col-sm-6">
                        <input type="text" formControlName="equipment_id" class="form-control" placeholder="Enter Equipment Id">
                        <app-form-error [controlObj]="equipmentForm.get('equipment_id')" label="equipment id">
                        </app-form-error>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-6 col-form-label">IP Address</label>
                    <div class="col-sm-6">
                        <input type="text" formControlName="ip_address" class="form-control" placeholder="Enter ip address">
                        <app-form-error [controlObj]="equipmentForm.get('ip_address')" label="ip address">
                        </app-form-error>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')" id="cancel-modal">Cancel</button>
            <button type="button" class="btn btn-success" (click)="save()">Submit</button>
        </div>
    </div>
</ng-template>
