<form [formGroup]="svidChamberForm" class="text-left" [ngClass]="{'form-validated': submitted}" (ngSubmit)="addSvidChamber()">
    <div class="row">
        <div class="col-md-4" >
            <div class="form-group">
                <label for="svid">SVID</label>
                <select id="svid" class="form-control" formControlName="svid">
                    <option *ngFor="let svid of svids" value="{{svid.id}}">{{svid.name}}</option>
                </select>
                <app-form-error [controlObj]="svidChamberForm.get('svid')" label="SVID"></app-form-error>
            </div>
        </div>

        <div class="col-md-4" >
            <div class="form-group">
                <label for="chamber_id">Chamber Id</label>
                <select id="chamber_id" class="form-control" formControlName="chamber_id">
                    <option *ngFor="let chamber of chambers" value="{{chamber.id}}">{{chamber.name}}</option>
                </select>
                <app-form-error [controlObj]="svidChamberForm.get('chamber_id')" label="Chamber Id"></app-form-error>
            </div>
        </div>
               
        <div class="col-md-4">
            <div class="form-group">
                <label for="number">number</label>
                <input type="text" id="number" class="form-control" formControlName="number" placeholder="Your Number">
                <app-form-error [controlObj]="svidChamberForm.get('number')" label="Number"></app-form-error>
            </div>
        </div>

    </div>

 

    <div class="row">
        <div class="col-md-12">
            <div class="pull-right">
                <button type="submit" class="btn btn-primary" [disabled]="buttonDisable" *ngIf="!svidChamber.value.id">Submit
                </button>&nbsp;
                <!-- <button class="btn btn-danger " type="submit" [disabled]="buttonDisable" *ngIf="parameter.value.id">Update
                </button>&nbsp; -->
                <button class="btn btn-primary " type="button" (click)="cancel()">Cancel</button>
            </div>
        </div>
    </div>

</form>