import { Injectable } from '@angular/core';
import {  HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private authService: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler):Observable<HttpEvent<any>> {
    let token = this.authService.isLoggedIn() ? this.authService.getToken() : null;
    let reqClone = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        Accept:'application/json'
      }
    });
    return next.handle(reqClone);
  }
}
