import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {}
  
  canActivate() : boolean {
    return true;
    // Check is logged in
    if(this.authService.isLoggedIn()) {
      return true;
    }
    this.router.navigate(["/"]);
    return false;
  } 
  
  
}
