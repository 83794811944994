import { NgModule } from '@angular/core';

import { AdminRoutingModule } from './admin-routing.module';
import { OverviewComponent } from './overview/overview.component';
import { SharedModule } from '../shared/shared.module';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgxPaginationModule } from 'ngx-pagination';
import { ChartsModule } from 'ng2-charts';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { DlDateTimeDateModule, DlDateTimePickerModule } from 'angular-bootstrap-datetimepicker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CreateUserComponent } from './user-creation/create-user/create-user.component';
import { UserListComponent } from './user-creation/user-list/user-list.component';
import { DepartmentComponent } from './department/department.component';
import { DesignationComponent } from './designation/designation.component';
import { EquipmentComponent } from './equipment/equipment.component';

import { ParameterListComponent } from './parameter/parameter-list/parameter-list.component';
import { CreateParameterComponent } from './parameter/create-parameter/create-parameter.component';
import { SvidChamperComponent } from './svid-champer/svid-champer.component';
// import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { DuplicateParameterComponent } from './parameter/duplicate-parameter/duplicate-parameter.component';
import { CreateChartDefinitionComponent } from './chart-definition/create-chart-definition/create-chart-definition.component';
import { ChartDefinitionListComponent } from './chart-definition/chart-definition-list/chart-definition-list.component';
import { SpcChartComponent } from './spc-chart/spc-chart.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { DatepickerModule, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { EmailListComponent } from './email-creation/email-list/email-list.component';
import { CreateEmailComponent } from './email-creation/create-email/create-email.component';
import { AlarmListComponent } from './alarm-setting/alarm-list/alarm-list.component';
import { CreateAlarmComponent } from './alarm-setting/create-alarm/create-alarm.component';

@NgModule({
  declarations: [
    OverviewComponent,
    CreateUserComponent,
    UserListComponent,
    DepartmentComponent,
    DesignationComponent,
    EquipmentComponent,
    ParameterListComponent,
    CreateParameterComponent,
    SvidChamperComponent,
    DuplicateParameterComponent,
    CreateChartDefinitionComponent,
    ChartDefinitionListComponent,
    SpcChartComponent,
    EmailListComponent,
    CreateEmailComponent,
    AlarmListComponent,
    CreateAlarmComponent,
    
  ],
  imports: [
    SharedModule,
    AdminRoutingModule,
    PaginationModule,
    NgxPaginationModule,
    // TypeaheadModule
    AutocompleteLibModule,
    NgMultiSelectDropDownModule,
    ChartsModule,
    DlDateTimeDateModule,  // <--- Determines the data type of the model
    DlDateTimePickerModule,
    NgbModule,
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
    
  ]
})
export class AdminModule { }
