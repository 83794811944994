<!-- <app-page-header>List Package</app-page-header> -->

<div class="row">
    <div class="col-md-3 mt-2">

        <button type="button" class="btn btn-primary btn-sm count-widget mb-2">
            <i class="fas fa-folder-open"></i>Total
            <span class="badge badge-info rounded-circle ml-1">{{userList.length}}</span>
        </button>
        <button type="button" class="btn btn-primary btn-sm common-btn ml-2 mb-2" [routerLink]="['/admin/create-user']">
            <i class="fas fa-plus-circle"></i>Add
        </button>
        <button type="button" class="btn btn-primary btn-sm common-btn ml-2 mb-2" (click)="refresh()">
            <i class="fas fa-sync-alt"></i>
        </button>
    </div>

    <div class="d-flex flex-row-reverse col-md-9" >
        <div class="p-2 mb-2">
            <form [formGroup]="searchForm" title="Search" >
                <div class="float-right quick-search-wrapper">
                    <div class="form-inline">
                        <input class="form-control" formControlName="search" name="search"
                               type="search" placeholder="Quick Search" aria-label="quick-search">
                        <button type="button" class="btn btn-primary ml-2" (click)="getUsers()">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                </div>
            </form>
        </div>     
    </div>
</div>

<div class="table-responsive-sm table-responsive-md table-responsive-lg table-responsive-xl">
    <!-- <div class="card"> -->
    <table class="table table-bordered">
        <thead>
            <tr>
                <th>#</th>
                <th>User Name</th>
                <th>Email ID</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Mobile</th>
                <th>Department</th>
                <th>Designation</th>
                <th>Action</th>
            </tr>
        </thead>

        <tbody>
            <tr *ngIf="showLoader">
                <td colspan="9" class="text-center">
                    <i class="fas fa-spinner fa-2x fa-spin mx-3" aria-hidden="true"></i>
                </td>
            </tr>
            <tr *ngIf="userList && userList.length == 0 && !showLoader">
                <td colspan="9" class="text-center"> No Records Found</td>
            </tr>
            <tr *ngFor="let user of userList | paginate: { itemsPerPage: 10, currentPage: pagination } let i=index;">
                <td>{{i + 1}}</td>
                <td>{{user.user_name}}</td>
                <td>{{user.email}}</td>
                <td>{{user.first_name}}</td>
                <td>{{user.last_name}}</td>
                <td>{{user.mobile}}</td>
                <td>{{user.department_name}}</td>
                <td>{{user.designation_name}}</td>
                <td>
                    <a class="btn btn-link btn-sm btn-icon-only" [routerLink]="['/admin/user/edit/',user.id]" title="Edit">
                       <i class="fa fa-edit"></i>
                    </a>
                    <a class="btn btn-link btn-sm btn-icon-only" title="Delete" (click)="delete(user.id)">
                        <i class="fa fa-trash"></i>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<pagination-controls (pageChange)="pagination = $event"></pagination-controls>



<!-- <app-pagination-content (getPagination)='listPackage(paginationObject)'
[paginationObject]="paginationObject"> </app-pagination-content> -->
