import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService} from '../../../auth/auth.service';
import { adminApi } from '../../admin.api';
import { searchForm } from '../../../shared/forms/searchForm';

@Component({
  selector: 'app-parameter-list',
  templateUrl: './parameter-list.component.html',
  styleUrls: ['./parameter-list.component.scss']
})
export class ParameterListComponent implements OnInit {

  showLoader : boolean = false;
  userList : any;
  search : any;
  pagination:any;
  parametersList:any = [];
  count:any;
  searchForm:any;
  
  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getParameter();
    this.searchForm = searchForm;
  }

  refresh() {
    this.searchForm.get('search').setValue("");
    this.getParameter();
  }

  getSearchList() { 
    this.getParameter();
  }

  getParameter() {
    this.showLoader = true;
    this.search = searchForm;
    let pageUrl = "?search=" + this.search.value.search; 
      this.http.get(adminApi.parameterList(pageUrl)).subscribe((result:any) => {
          this.parametersList = result.data;
          console.log(this.parametersList);
          
          this.count = this.parametersList.length;
          this.showLoader = false;
      });        
  }
  
  delete(id:number) {
    if (confirm("Are you sure you want to delete?")) {
      this.http.delete(adminApi.deleteParameter(id)).subscribe((result:any) => {
        this.toastr.success("Parameter deleted successfully");
        this.refresh();
      },(errorObj: any) => {
        this.toastr.error(errorObj.error.message);
      }
      );
    }
  }

}
