<form [formGroup]="emailForm" class="text-left" [ngClass]="{'form-validated': submitted}" (ngSubmit)="addParameter()">
    <div class="row">
        <div class="col-md-4">
            <div class="form-group">
                <label for="name">Name</label>
                <input type="text" id="name" class="form-control" formControlName="name" placeholder="Your Name">
                <app-form-error [controlObj]="emailForm.get('name')" label="Name"></app-form-error>
            </div>
        </div>
        <div class="col-md-4" >
            <div class="form-group">
                <label for="email">Email</label>
                <input type="text" id="email" class="form-control" formControlName="email" placeholder="Your Email">
                <app-form-error [controlObj]="emailForm.get('email')" label="Email"></app-form-error>
            </div>
        </div>

        <div class="col-md-4" >
            <div class="form-group">
                <label for="number">Phone Number</label>
                <input type="text" id="number" class="form-control" formControlName="number" placeholder="Your Number">
                <app-form-error [controlObj]="emailForm.get('number')" label="Number"></app-form-error>
            </div>
        </div>
        
      
     
    </div>

 

    <div class="row">
        <div class="col-md-12">
            <div class="pull-right">
                <button type="submit" class="btn btn-primary" [disabled]="buttonDisable" *ngIf="!email.value.id">Submit
                </button>&nbsp;
                <button class="btn btn-danger " type="submit" [disabled]="buttonDisable" *ngIf="email.value.id">Update
                </button>&nbsp;
                <button class="btn btn-primary " type="button" (click)="cancel()">Cancel</button>
            </div>
        </div>
    </div>

</form>
