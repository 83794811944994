import { Component, OnInit } from '@angular/core';
import { AlarmSettingForm } from './alarm-setting.form';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup,FormArray,FormControl,FormBuilder, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { adminApi } from '../../admin.api';
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'app-create-alarm',
  templateUrl: './create-alarm.component.html',
  styleUrls: ['./create-alarm.component.scss']
})
export class CreateAlarmComponent implements OnInit {

  alarmSetting:any;
  alarmSettingForm: FormGroup = AlarmSettingForm;
  showLoader: boolean = false;
  submitted: boolean = false;
  buttonDisable:boolean = false;
  equipments:any;
  emails:any;
  chartsList:any;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.alarmSetting = this.alarmSettingForm;

    this.route.paramMap.subscribe(params => {
      const alarmId = params.get('id');
      if(alarmId) {
        this.getAlarmSettingById(alarmId)
      }
    });
    (this.alarmSetting.get("emails") as FormArray)['controls'].splice(0);
    (this.alarmSetting.get("charts") as FormArray)['controls'].splice(0);
    this.getEquipmentModel();
    this.getAllEmails();
    this.getAllChart();
  }

  getEquipmentModel() {
    this.http.get(adminApi.equipmentListAll()).subscribe((result:any) => {
      this.equipments = result.data;
    });
  }

  getAllEmails() {
    this.http.get(adminApi.getAllEmails()).subscribe((result:any) => {
      this.emails = result.data;
    })
  }

  getAllChart() {
    this.http.get(adminApi.getAllChart()).subscribe((result:any) => {
      this.chartsList = result.data
    })
  }

  getAlarmSettingById(alarmId:any) {
    (this.alarmSetting.get("emails") as FormArray)['controls'].splice(0);
    (this.alarmSetting.get("charts") as FormArray)['controls'].splice(0);
    this.http.get(adminApi.getAlarmById(alarmId)).subscribe((result:any) => {
      this.alarmSetting.patchValue(result.data[0]);
      result.data[0].alarm_chart.forEach((chart:any) => { 
        this.chart.push(this.fb.group({chart_id:chart.chart_definition.id}))  
      });
      result.data[0].alarm_email.forEach((email:any) => {
      this.email.push(this.fb.group({email_id:email.email.id}))  
      })
    })
  }




  ////////////////////////////////////Emails Array//////////////////////////////////////
  get email() : FormArray {
    return this.alarmSetting.get('emails') as FormArray;
  }

  newEmail(): FormGroup {
    return this.fb.group({
      email_id: ['',Validators.required]
    })
 }

  addEmail() {
    this.email.push(this.newEmail());
  }

  deleteEmail(index:any) {
    this.email.removeAt(index);
  }

  AddMoreEmail() {
    this.addEmail();
  }
  ///////////////////////////////////End of Email////////////////////////////////////////

  //////////////////////////////////Add Chart Array///////////////////////////////////////

  get chart() : FormArray {
    return this.alarmSetting.get('charts') as FormArray;
  }

  newChart(): FormGroup {
    return this.fb.group({
      chart_id: ['',Validators.required]
    })
 }

  addChart() {
    this.chart.push(this.newChart());
  }

  deleteChart(index:any) {
    this.chart.removeAt(index);
  }

  AddMoreChart() {
    this.addChart();
  }

  ///////////////////////////////////End of Chart////////////////////////////////////////


  createAlarm() {
    this.submitted = true;
    this.showLoader = true;
    this.buttonDisable = true;
    if(this.alarmSetting.invalid) {
      this.buttonDisable = false; 
      return;
    }
    console.log(this.alarmSetting.value);
    this.alarmSetting.get('created_by').setValue(this.authService.getLoginId());
    if(this.alarmSetting.value.id) {
      this.http.put(adminApi.updateAlarm(),this.alarmSetting.value).subscribe((result:any) => {
        this.alarmSetting.get('alarm_name').setValue('');
        this.alarmSetting.get('equipment_model_id').setValue('');
        this.submitted = false;
        this.alarmSetting.reset();
        this.toastr.success("Alarm setting updated successfully!");
        this.router.navigate(['/admin/alarm-list']);
      },(errorObj: any) => {
        console.log(errorObj);
        if(errorObj.error.message == "Please Add Atleast One Emails") {
          this.toastr.error(errorObj.error.message);
        }else if(errorObj.error.message == "Please Add Atleast One Charts") {
          this.toastr.error(errorObj.error.message);
        }else {
          this.toastr.error(errorObj.error.errors.alarm_name[0]);
        }
        this.submitted = false;
        this.showLoader = false;
        this.buttonDisable = false;
      });
    } else {
      this.http.post(adminApi.createAlarm(),this.alarmSetting.value).subscribe((result:any) => {
        this.alarmSetting.get('alarm_name').setValue('');
        this.alarmSetting.get('equipment_model_id').setValue('');
        this.submitted = false;

        this.alarmSetting.reset();
        this.toastr.success("Alarm setting added successfully!");
        this.router.navigate(['/admin/alarm-list']);
      },(errorObj: any) => {
        console.log(errorObj);
        if(errorObj.error.message == "Please Add Atleast One Emails") {
          this.toastr.error(errorObj.error.message);
        }else if(errorObj.error.message == "Please Add Atleast One Charts") {
          this.toastr.error(errorObj.error.message);
        }else {
          this.toastr.error(errorObj.error.errors.alarm_name[0]);
        }
        this.submitted = false;
        this.showLoader = false;
        this.buttonDisable = false;
      });
    }
  }

  cancel() {
    this.alarmSetting.reset();
    this.router.navigate(['/admin/alarm-list']);
  }

}
