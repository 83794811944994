<form [formGroup]="parameterForm" class="text-left" [ngClass]="{'form-validated': submitted}" (ngSubmit)="addParameter()">
    <div class="row">
        <div class="col-md-4">
            <div class="form-group">
                <label for="name">Parameter name</label>
                <input type="text" id="name" class="form-control" formControlName="name" placeholder="Your Parameter name">
                <app-form-error [controlObj]="parameterForm.get('name')" label="Parameter Name"></app-form-error>
            </div>
        </div>
        <div class="col-md-4" >
            <div class="form-group">
                <label for="description">Description</label>
                <input type="text" id="description" class="form-control" formControlName="description" placeholder="Your Description">
                <app-form-error [controlObj]="parameterForm.get('description')" label="Description"></app-form-error>
            </div>
        </div>
        
        <div class="col-md-4" >
            <div class="form-group">
                <label for="equipment_model">Equipment Model</label>
                <select id="equipment_model" class="form-control" formControlName="equipment_model_id">
                    <option hidden value="" disabled selected>Select </option>
                        <option [value]="equipment.id" *ngFor="let equipment of equipments">{{equipment.name}}</option> 
                </select>
                <app-form-error [controlObj]="parameterForm.get('equipment_model_id')" label="Equipment Model"></app-form-error>
            </div>
        </div>

        <div class="col-md-4" >
            <div class="form-group">
                <label for="svid">SVID</label>
                <ng-autocomplete
                    [data]="svids"
                    [searchKeyword]="keyword"
                    (selected)='selectSVID($event)'
                    [itemTemplate]="itemTemplate"
                    [notFoundTemplate]="notFoundTemplate"
                    formControlName = "svid"
                    placeholder="Enter SVID">
                </ng-autocomplete>

                <ng-template #itemTemplate let-svid>
                    <a [innerHTML]="svid.name"></a>
                  </ng-template>
                
                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>
                <!-- <select id="svid" class="form-control" formControlName="svid">
                    <option *ngFor="let svid of svids" value="{{svid.id}}">{{svid.name}}</option>
                </select> -->
                <app-form-error [controlObj]="parameterForm.get('svid')" label="SVID"></app-form-error>
            </div>
        </div>

        <div class="col-md-4" >
            <div class="form-group">
                <label for="chamber_id">Chamber Id</label>
                <ng-autocomplete 
                    [data]="chambers"
                    [searchKeyword]="keyword"
                    (selected)='selectChamber($event)'
                    [itemTemplate]="itemTemplate"
                    [notFoundTemplate]="notFoundTemplate"
                    formControlName = "chamber_id"
                    placeholder="Enter Chamber Id">
                </ng-autocomplete>

                <ng-template #itemTemplate let-chamber>
                    <a [innerHTML]="chamber.name"></a>
                  </ng-template>
                
                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>
                <!-- <select id="chamber_id" class="form-control" formControlName="chamber_id">
                    <option *ngFor="let chamber of chambers" value="{{chamber.id}}">{{chamber.name}}</option>
                </select> -->
                <app-form-error [controlObj]="parameterForm.get('chamber_id')" label="Chamber Id"></app-form-error>
            </div>
        </div>

        <div class="col-md-4" >
            <div class="form-group">
                <label for="receipe_name">Receipe Name</label>
                <ng-autocomplete
                    [data]="receipes"
                    [searchKeyword]="keyword"
                    (selected)='selectReceipe($event)'
                    [itemTemplate]="itemTemplate"
                    [notFoundTemplate]="notFoundTemplate"
                    (inputChanged)='onChangeSearchReceipe($event)'
                    formControlName = "receipe_name"
                    placeholder="Enter Receipe Name">
                </ng-autocomplete>

                <ng-template #itemTemplate let-receipe>
                    <a [innerHTML]="receipe.name"></a>
                  </ng-template>
                
                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>
                <!-- <select id="receipe_name" class="form-control" formControlName="receipe_name">
                    <option  value="dsada">hgjhgj</option>
                </select> -->
                <app-form-error [controlObj]="parameterForm.get('receipe_name')" label="Receipe Name"></app-form-error>
            </div>
        </div>

        <div class="col-md-4">
            <div class="form-group">
                <label for="steps">Steps</label>
                <ng-autocomplete
                    [data]="steps"
                    [searchKeyword]="keyword"
                    (selected)='selectSteps($event)'
                    (inputChanged)='onChangeSearchStep($event)'
                    [itemTemplate]="itemTemplate"
                    [notFoundTemplate]="notFoundTemplate"
                    formControlName = "steps"
                    placeholder="Enter Steps">
                </ng-autocomplete>

                <ng-template #itemTemplate let-step>
                    <a [innerHTML]="step.name"></a>
                  </ng-template>
                
                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>
                <!-- <select id="steps" class="form-control" formControlName="steps">
                    <option  value="dsada">hgjhgj</option>
                </select> -->
                <app-form-error [controlObj]="parameterForm.get('steps')" label="Steps"></app-form-error>
            </div>
        </div>

        <div class="col-md-4" >
            <div class="form-group">
                <label for="loop">Loop</label>
                <ng-autocomplete
                    [data]="loops"
                    [searchKeyword]="keyword"
                    (selected)='selectLoop($event)'
                    (inputChanged)='onChangeSearchLoop($event)'
                    [itemTemplate]="itemTemplate"
                    [notFoundTemplate]="notFoundTemplate"
                    formControlName = "loop"
                    placeholder="Enter Loop">
                </ng-autocomplete>

                <ng-template #itemTemplate let-loop>
                    <a [innerHTML]="loop.name"></a>
                  </ng-template>
                
                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>
                <!-- <select id="loop" class="form-control" formControlName="loop">
                    <option  value="asda">hgjhgj</option>
                </select> -->
                <app-form-error [controlObj]="parameterForm.get('loop')" label="Loop"></app-form-error>
            </div>
        </div>

               
    </div>

 

    <div class="row">
        <div class="col-md-12">
            <div class="pull-right">
                <button type="submit" class="btn btn-primary" [disabled]="buttonDisable" *ngIf="!parameter.value.id">Submit
                </button>&nbsp;
                <button class="btn btn-danger " type="submit" [disabled]="buttonDisable" *ngIf="parameter.value.id">Update
                </button>&nbsp;
                <button class="btn btn-primary " type="button" (click)="cancel()">Cancel</button>
            </div>
        </div>
    </div>

</form>
