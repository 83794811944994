import {  FormBuilder, FormGroup, FormArray, Validators } from "@angular/forms";

let fb = new FormBuilder();
let fbGroup = new FormBuilder();
let fbArray = new FormBuilder();

let SpcChartForm: FormGroup = fb.group({
    equipment_model_id:['',Validators.required],
    svid : ['',Validators.required],
    parameter: ['',Validators.required],
    start_date: ['',Validators.required],
    start_time: ['',Validators.required],
    end_date: ['',Validators.required],
    end_time: ['',Validators.required],
    created_by :['']
})

export {
    SpcChartForm
}
