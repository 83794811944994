import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService} from '../../../auth/auth.service';
import { adminApi } from '../../admin.api';
import { searchForm } from '../../../shared/forms/searchForm';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

  showLoader : boolean = false;
  userList : any = [];
  search : string = "";
  pagination:any;
  searchForm:any;
    
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private toastr: ToastrService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.searchForm = searchForm;
    this.getUsers();
  }

  getUsers() {
    this.showLoader = true;
    let pageUrl = "?search=" + this.searchForm.value.search;
    this.http.get(adminApi.userList(pageUrl)).subscribe((result:any) => {
        this.userList = result.data;
        this.showLoader = false;
    })
  }

  refresh() {
    this.searchForm.get('search').setValue("");
    this.getUsers();
  }
  
  delete(id:number) {
    if (confirm("Are you sure you want to delete?")) {
      this.http.delete(adminApi.deleteUser(id)).subscribe((result:any) => {
        this.toastr.success("User deleted successfully");
        this.refresh();
      });
    }
  }

}
