import { Component, OnInit } from '@angular/core';
import { ChartDefinitionForm } from './chart-definition.form';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup,FormArray,FormControl,FormBuilder, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { adminApi } from '../../admin.api';
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'app-create-chart-definition',
  templateUrl: './create-chart-definition.component.html',
  styleUrls: ['./create-chart-definition.component.scss']
})
export class CreateChartDefinitionComponent implements OnInit {
  
  chartDefinition:any;
  chartDefinitionForm: FormGroup = ChartDefinitionForm;
  showLoader: boolean = false;
  submitted: boolean = false;
  buttonDisable:boolean = false;
  equipments:any;
  parametersValue:any;
  keyword = 'name';
  
  constructor(
    private router: Router,
    private toastr: ToastrService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {    
    this.chartDefinition = this.chartDefinitionForm;
    
    this.route.paramMap.subscribe(params => {
      const chartId = params.get('id');
      if(chartId) {
        this.getChartById(chartId)
      }
    });
    (this.chartDefinition.get("parameters") as FormArray)['controls'].splice(0);
    // this.chartDefinition.reset();
    this.getEquipmentModel();
    this.getParameterByUserId();
  }
  

  getChartById(chartId:any) {
    (this.chartDefinition.get("parameters") as FormArray)['controls'].splice(0);
    this.http.get(adminApi.getChartById(chartId)).subscribe((result:any) => {
      this.chartDefinition.patchValue(result.data[0]);
      console.log(this.chartDefinition);
      result.data[0].chart_parameter.forEach((obj:any) => {
      this.parameter.push(this.fb.group({parameter_id:obj.parameter}))
      });
    })
  }

  getEquipmentModel() {
    this.http.get(adminApi.equipmentListAll()).subscribe((result:any) => {
      this.equipments = result.data;
    });
  }

  getParameterByUserId() {
    this.http.get(adminApi.getParameterByUserId(this.authService.getLoginId())).subscribe((result:any) => {
      this.parametersValue = result.data;
    })
  }

  get parameter() : FormArray {
    return this.chartDefinition.get('parameters') as FormArray;
  }

  newParameter(): FormGroup {
    return this.fb.group({
      parameter_id: ['',Validators.required]
    })
 }


  
  addParameter() {
    // this.parameter.push(this.fb.group({parameter_id:''}));
    
    this.parameter.push(this.newParameter());
  }

  delete(index:any) {
    this.parameter.removeAt(index);
  }
 
  cancel() {
    this.chartDefinition.reset();
    this.router.navigate(['/admin/chart-definition-list']);
  }

  addChart(){
    this.submitted = true;
    this.showLoader = true;
    this.buttonDisable = true;
    if(this.chartDefinition.invalid) {
      this.buttonDisable = false; 
      return;
    }
    this.chartDefinition.get('created_by').setValue(this.authService.getLoginId());
    if(this.chartDefinition.value.id) {
      var parameterUCL: number = +this.chartDefinition.value.parameter_ucl;
      var parameterUSL: number = +this.chartDefinition.value.parameter_usl;
      if(parameterUCL >= parameterUSL) {
        this.buttonDisable = false; 
        this.toastr.error("UCL value should be less than USL");
        return;
      }
      var centerLine: number = +this.chartDefinition.value.center_line;
      var parameterUCL: number = +this.chartDefinition.value.parameter_ucl;
      if(parameterUCL <= centerLine) {
        this.buttonDisable = false; 
        this.toastr.error("Center Line value should be less than UCL");
        return
      }
      var centerLine: number = +this.chartDefinition.value.center_line;
      var parameterLCL: number = +this.chartDefinition.value.parameter_lcl;
      if(centerLine <= parameterLCL) {
        this.buttonDisable = false; 
        this.toastr.error("LCL value should be less than Center Line");
        return;
      }
      var parameterLSL: number = +this.chartDefinition.value.parameter_lsl;
      var parameterLCL: number = +this.chartDefinition.value.parameter_lcl;
      if(parameterLCL <= parameterLSL) {
        this.buttonDisable = false; 
        this.toastr.error("LSL value should be less than LCL");
        return;
      }else{
        this.http.put(adminApi.updateChart(),this.chartDefinition.value).subscribe((result:any) => {
          this.chartDefinition.reset();
          this.submitted = false;
          this.toastr.success("Chart updated successfully!");
          this.router.navigate(['/admin/chart-definition-list']);
        },(errorObj: any) => {
          if(errorObj.error.message == "Please Add Atleast One Parameter") {
            this.toastr.error(errorObj.error.message);
          }else if(errorObj.error.message == "Parameter Already choosen. Please choose another one"){
            this.toastr.error(errorObj.error.message);
          }else {
            this.toastr.error(errorObj.error.errors.chart_name[0]);
          }
          this.submitted = false;
          this.showLoader = false;
          this.buttonDisable = false;
        });
      }
    } else {
      var parameterUCL: number = +this.chartDefinition.value.parameter_ucl;
      var parameterUSL: number = +this.chartDefinition.value.parameter_usl;
      if(parameterUCL >= parameterUSL) {
        this.buttonDisable = false; 
        this.toastr.error("UCL value should be less than USL");
        return;
      }
      var centerLine: number = +this.chartDefinition.value.center_line;
      var parameterUCL: number = +this.chartDefinition.value.parameter_ucl;
      if(parameterUCL <= centerLine) {
        this.buttonDisable = false; 
        this.toastr.error("Center Line value should be less than UCL");
        return
      }
      var centerLine: number = +this.chartDefinition.value.center_line;
      var parameterLCL: number = +this.chartDefinition.value.parameter_lcl;
      if(centerLine <= parameterLCL) {
        this.buttonDisable = false; 
        this.toastr.error("LCL value should be less than Center Line");
        return;
      }
      var parameterLSL: number = +this.chartDefinition.value.parameter_lsl;
      var parameterLCL: number = +this.chartDefinition.value.parameter_lcl;
      if(parameterLCL <= parameterLSL) {
        this.buttonDisable = false; 
        this.toastr.error("LSL value should be less than LCL");
        return;
      }else {
        this.http.post(adminApi.createChart(),this.chartDefinition.value).subscribe((result:any) => {
          this.chartDefinition.reset();
          this.submitted = false;
          this.toastr.success("Chart added successfully!");
          this.router.navigate(['/admin/chart-definition-list']);
        },(errorObj: any) => {
          console.log(errorObj);

          if(errorObj.error.message == "Please Add Atleast One Parameter") {
            this.toastr.error(errorObj.error.message);
          }else if(errorObj.error.message == "Parameter Already choosen. Please choose another one"){
            this.toastr.error(errorObj.error.message);
          }else {
            this.toastr.error(errorObj.error.errors.chart_name[0]);
          }
          
          
          this.submitted = false;
          this.showLoader = false;
          this.buttonDisable = false;
          // this.router.navigate(['/admin/chart-definition-list']);
        });
      }
      
    }
    
  }

  selectParameter(parameter_id:any) {
    console.log(parameter_id);
    
  }

  numberOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 43 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
   return true;
  }


  AddMoreParameter() {
    this.addParameter();
  }

}
