import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PermissionDirective } from './directives/permission.directive';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { FormErrorComponent } from './form-error/form-error.component';
import { LayoutsModule } from '../layouts/layouts.module';

// Toster module
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';


@NgModule({
  declarations: [
    PermissionDirective,
    FormErrorComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    LayoutsModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    LayoutsModule,
    PermissionDirective,
    FormErrorComponent,
    BrowserAnimationsModule,
    ToastrModule
  ]
})
export class SharedModule { }
