<div class="row">
    <div class="col-md-3 mt-2">
        <button type="button" class="btn btn-primary btn-sm count-widget mb-2">
            <i class="fas fa-folder-open "></i>Total
            <span class="badge badge-info rounded-circle ml-1">{{emailsList.length}}</span>
        </button>
        <button type="button" class="btn btn-primary btn-sm common-btn ml-2 mb-2" [routerLink]="['/admin/create-email']">
            <i class="fas fa-plus-circle"></i>Add
        </button>
        <button type="button" class="btn btn-primary btn-sm common-btn ml-2 mb-2" (click)="refresh()">
            <i class="fas fa-sync-alt"></i>
        </button>
    </div>

    <div class="d-flex flex-row-reverse col-md-9" >
        <div class="p-2 mb-2">
            <form [formGroup]="search" (ngSubmit)="getSearchList()" title="Search" >
                <div class="float-right quick-search-wrapper">
                    <div class="form-inline">
                        <input class="form-control" formControlName="search" name="search1"
                            type="search" placeholder="Quick Search" aria-label="quick-search">
                        <button type="submit" class="btn btn-danger" (click)="getSearchList()">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    
</div>

<div class="table-responsive-sm table-responsive-md table-responsive-lg table-responsive-xl" style="overflow-x:auto;">
    <!-- <div class="card"> -->
    <table class="table table-bordered">
        <thead>
            <tr>
                <th>#</th>
                <th>Name</th>
                <th>Email</th>
                <th>Number</th>
                <th>Created At</th>
                <th>Updated At</th>
                <th>Action</th>
            </tr>
        </thead>

        <tbody>
            <tr *ngIf="showLoader">
                <td colspan="7" class="text-center">
                    <i class="fas fa-spinner fa-2x fa-spin mx-3" aria-hidden="true"></i>
                </td>
            </tr>
            <tr *ngIf="emailsList && emailsList.length == 0 && !showLoader">
                <td colspan="7" class="text-center"> No Records Found</td>
            </tr>
            <tr *ngFor="let email of emailsList | paginate: { itemsPerPage: 10, currentPage: pagination } let i=index;">
                    <td>{{i + 1 }}</td>
                    <td>{{email.name}}</td>
                    <td>{{email.email}}</td>
                    <td>{{email.number}}</td>
                    <td>{{email.created_at}}</td>
                    <td>{{email.updated_at}}</td>
                    <td>
                        <a class="btn btn-link btn-sm btn-icon-only" [routerLink]="['/admin/email/edit/',email.id]" title="Edit">
                           <i class="fa fa-edit"></i>
                        </a>
                        <a class="btn btn-link btn-sm btn-icon-only" title="Delete" (click)="delete(email.id)">
                            <i class="fa fa-trash"></i>
                        </a>
                    </td>
            </tr>
        </tbody>
    </table>
</div>
<pagination-controls (pageChange)="pagination = $event"></pagination-controls>
