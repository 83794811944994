import { NgModule } from '@angular/core';
import { AuthService } from './auth.service';
import { AuthInterceptorService } from './auth-interceptor.service';
import { LoginComponent } from './login/login.component';
import { AuthRoutingModule } from './auth-routing.module';
import { SharedModule } from '../shared/shared.module';
import { AuthGuard } from './auth.guard';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    SharedModule,
    AuthRoutingModule,
  ],
  providers: [
    AuthService,
    AuthInterceptorService,
    AuthGuard
  ],
  declarations: [
    LoginComponent
  ],
})
export class AuthModule {}