import { Component, OnInit,ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { adminApi } from '../../admin.api';
import { searchForm } from '../../../shared/forms/searchForm';
import {FormGroup, FormBuilder, FormControl, Validators,FormArray} from '@angular/forms';
import { AuthService } from '../../../auth/auth.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ChartDefinitionListForm } from './chart-definition-list.form';

@Component({
  selector: 'app-chart-definition-list',
  templateUrl: './chart-definition-list.component.html',
  styleUrls: ['./chart-definition-list.component.scss']
})
export class ChartDefinitionListComponent implements OnInit {
  @ViewChild('closebutton') closebutton:any;

  showLoader : boolean = false;
  chartList : any;
  pagination:any;
  count:any;
  searchForm:any;
  search:any;
  chartId:any;
  parameters:any;
  chartDefinitionList:any;
  chartDefinitionListForm: FormGroup = ChartDefinitionListForm;
  closeResult:string= "";
  keyword = 'name';
  userParameters:any;
  submitted: boolean = false;
  buttonDisable:boolean = false;

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private authService: AuthService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.chartDefinitionList = this.chartDefinitionListForm;
    this.getChartDefinitionList();
    this.searchForm = searchForm;
    // this.getParameterByUserId();
    (this.chartDefinitionList.get("parameters") as FormArray)['controls'].splice(0);
  }

  refresh() {
    this.searchForm.get('search').setValue("");
    this.getChartDefinitionList();
  }

  getParameterByUserId() {
    this.http.get(adminApi.getParameterByUserId(this.authService.getLoginId())).subscribe((result:any) => {
      this.userParameters = result.data;
    })
  }

  getChartDefinitionList() {
    this.search = searchForm;
    this.showLoader = true;
    let pageUrl = "?search=" + this.search.value.search; 
      this.http.get(adminApi.chartList(pageUrl)).subscribe((result:any) => {
          this.chartList = result.data;
          this.count = this.chartList.length;
          this.showLoader = false;
      });     
  }

  getSearchList() { 
    this.getChartDefinitionList();
  }

  get userParameter() : FormArray {
    return this.chartDefinitionList.get('parameters') as FormArray;
  }

  newParameter(): FormGroup {
    return this.fb.group({
      parameter_id: ['',Validators.required]
    })
 }

  
  addParameter() {
    this.userParameter.push(this.newParameter());
  }

  deleteParameter(index:any) {
    this.userParameter.removeAt(index);
    this.addMoreButtonVisible = true;
  }

  selectParameter(parameter_id:any) {
    console.log(parameter_id);
    
  }
  addMoreButtonVisible : boolean = true;
  AddMoreParameter() {
    this.addMoreButtonVisible = false;
    this.addParameter();
  }

  
  viewParameter(chart: any) {
    this.chartId = chart.id;
    this.parameters = chart.chart_parameter;
  }
  chartData:any;
  openModal(chartValue:any) {
    (this.chartDefinitionList.get("parameters") as FormArray)['controls'].splice(0);
    this.getParameterByUserId();
    this.chartData = chartValue
    this.addMoreButtonVisible = true;
  }

  closeModal() {
    this.addMoreButtonVisible = true;
    this.submitted = false;
    // this.chartDefinitionList.clearValidators();
  }

  save() {
    this.submitted = true;
    if(this.chartDefinitionList.invalid) {
      this.buttonDisable = false; 
      return;
    }
    this.chartDefinitionList.get('chart_id').setValue(this.chartData.id)
    this.chartDefinitionList.get('created_by').setValue(this.authService.getLoginId());    
    this.http.post(adminApi.updateChartDefinitionParameter(),this.chartDefinitionList.value).subscribe((result:any) => {
        this.toastr.success('Parameter Added Successfully');
        this.getChartDefinitionList();
        this.closebutton.nativeElement.click();
        this.addMoreButtonVisible = true;
    },(errorObj: any) => {      
      this.toastr.error(errorObj.error.message);
    })
    
  }

  delete(id:number) {
    if (confirm("Are you sure you want to delete?")) {
      this.http.delete(adminApi.deleteChartDefinition(id)).subscribe((result:any) => {
        this.toastr.success("Chart deleted successfully");
        this.refresh();
      });
    }
  }

}
