<div class="row">
    <div class="col-md-3 mt-2">

        <button type="button" class="btn btn-primary btn-sm count-widget mb-2">
            <i class="fas fa-folder-open "></i>
            Total
            <span class="badge badge-info rounded-circle ml-1">{{count}}</span>
        </button>
        <button type="button" class="btn btn-primary btn-sm common-btn ml-2 mb-2" [routerLink]="['/admin/create-alarm']">
                <i class="fas fa-plus-circle"></i>
            Add
        </button>
        <button type="button" class="btn btn-primary btn-sm common-btn ml-2 mb-2" >
            <i class="fas fa-sync-alt" (click)="refresh()"></i>
        </button>
    </div>

    <div class="d-flex flex-row-reverse col-md-9" >
            <div class="p-2 mb-2">
                <form [formGroup]="search" (ngSubmit)="getSearchList()" title="Search" >
                    <div class="float-right quick-search-wrapper">
                        <div class="form-inline">
                            <input class="form-control" formControlName="search" name="search1"
                                type="search" placeholder="Quick Search" aria-label="quick-search">
                            <button type="submit" class="btn btn-danger" (click)="getSearchList()">
                                <i class="fas fa-search"></i>
                            </button>
                        </div>
                    </div>
                </form>
        </div>
     
    </div>
</div>

<div class="table-responsive-sm table-responsive-md table-responsive-lg table-responsive-xl" style="overflow-x:auto;">
    <!-- <div class="card"> -->
    <table class="table table-bordered">
        <thead>
            <tr>
                <th>#</th>
                <th>Alarm Name</th>
                <th>Chart's</th>
                <th>Email Id's</th>
                <th>Created At</th>
                <th>Updated At</th>
                <th>Action</th>
            </tr>
        </thead>

        <tbody>
            <tr *ngIf="showLoader">
                <td colspan="7" class="text-center">
                    <i class="fas fa-spinner fa-2x fa-spin mx-3" aria-hidden="true"></i>
                </td>
            </tr>
            <tr *ngIf="alarmLists && alarmLists.length == 0 && !showLoader">
                <td colspan="7" class="text-center"> No Records Found</td>
            </tr>
            <tr *ngFor="let alarm of alarmLists | paginate: { itemsPerPage: 10, currentPage: pagination } let i=index;">
                <td>{{ i + 1 }}</td>
                <td>{{alarm.alarm_name}}</td>
                <td ><button class="btn btn-link btn-sm btn-icon-only" data-toggle="modal" 
                    data-target="#chartModal" (click)="viewChart(alarm)">
                    <i class="fa fa-eye" aria-hidden="true"></i>&nbsp;View Chart</button></td>
                <td ><button class="btn btn-link btn-sm btn-icon-only" data-toggle="modal" 
                    data-target="#emailModal" (click)="viewEmail(alarm)">
                    <i class="fa fa-eye" aria-hidden="true"></i>&nbsp;View Email</button></td>
                <td>{{alarm.created_at}}</td>
                <td>{{alarm.updated_at}}</td>
                <td>
                    <a class="btn btn-link btn-sm btn-icon-only" [routerLink]="['/admin/alarm/edit/',alarm.id]" title="Edit">
                       <i class="fa fa-edit"></i>
                    </a>
                    <a class="btn btn-link btn-sm btn-icon-only" title="Delete" (click)="delete(alarm.id)">
                        <i class="fa fa-trash"></i>
                    </a>
                    <a class="btn btn-link btn-sm btn-icon-only" title="Add Chart" data-toggle="modal" data-target="#AddChartModal" (click)="openChartModal(alarm)">
                        <!-- <i class="fa fa-plus"></i> -->
                        <button type="button" class="btn btn-primary btn-sm common-btn ml-2 mb-2">Add chart
                        </button>
                    </a>
                    <a class="btn btn-link btn-sm btn-icon-only" title="Add Email" data-toggle="modal" data-target="#AddEmailModal" (click)="openEmailModal(alarm)">
                        <!-- <i class="fa fa-plus"></i> -->
                        <button type="button" class="btn btn-primary btn-sm common-btn ml-2 mb-2">Add Email
                        </button>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<pagination-controls (pageChange)="pagination = $event"></pagination-controls>


<!------------------------------------ VIEW CHART MODAL --------------------------------->
<div class="modal fade" id="chartModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
aria-hidden="true">
<div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalScrollableTitle">&nbsp;Charts</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="card">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th> # </th>
                            <th>Chart Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="showLoader">
                            <td colspan="4" class="text-center">
                                <i class="fas fa-spinner fa-2x fa-spin mx-3" aria-hidden="true"></i>
                            </td>
                        </tr>
                        <tr *ngIf="charts && charts.length == 0 && !showLoader">
                            <td colspan="4" class="text-center"> No Records Found</td>
                        </tr>
                        <tr *ngFor="let chart of charts; let i=index;">
                            <td>{{ i + 1}}</td>
                            <td>{{ chart.chart_definition.chart_name }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
</div>
<!------------------------------------ END OF VIEW CHART MODAL --------------------------------->


<!------------------------------------ VIEW EMAIL MODAL --------------------------------->
<div class="modal fade" id="emailModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
aria-hidden="true">
<div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalScrollableTitle">&nbsp;Emails</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="card">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th> # </th>
                            <th>Email Id's</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="showLoader">
                            <td colspan="4" class="text-center">
                                <i class="fas fa-spinner fa-2x fa-spin mx-3" aria-hidden="true"></i>
                            </td>
                        </tr>
                        <tr *ngIf="emails && emails.length == 0 && !showLoader">
                            <td colspan="4" class="text-center"> No Records Found</td>
                        </tr>
                        <tr *ngFor="let email of emails; let i=index;">
                            <td>{{ i + 1}}</td>
                            <td>{{ email.email.email }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
</div>
<!------------------------------------ END OF VIEW EMAIL MODAL --------------------------------->

<!------------------------------------- ADD MORE CHART MODAL -------------------------------->
<div class="modal fade" id="AddChartModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
aria-hidden="true">
<div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalScrollableTitle">&nbsp;Add Chart</h5>
            <button type="button" #closeChartButton class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <button type="button" *ngIf="addMoreButtonVisible == true" class="btn btn-primary btn-sm common-btn ml-2 mb-2"(click)="AddMoreChart()">    
                Add Chart
            </button>
                <form [formGroup]="alarmChart" class="text-left" [ngClass]="{'form-validated': submitted}" (ngSubmit)="saveChart()">
                    <div formArrayName="charts">
                        <div *ngFor="let ch of chart.controls; let i = index;">
                           <div [formGroupName]="i">
                            <div class="row">
                                <div class="col-md-4" >
                                    <div class="form-group">
                                        <label for="">Charts</label>
                                        <select id="chart" class="form-control" formControlName="chart_id" required>
                                            <option hidden value="" disabled selected>Select </option>
                                                <option [value]="charts.id" *ngFor="let charts of chartsList">{{charts.chart_name}}</option> 
                                        </select>
                                          <app-form-error [controlObj]="ch.get('chart_id')" label="Chart"></app-form-error>
                                          <span (click)="deleteChart(i)" class=" mt-2 ml-2 fa fa-times text-danger"></span>
                                    </div>
                                </div>
                            </div>
                           </div>
                        </div>
                    </div>

                    <button type="button" class="btn btn-secondary" style="float: right;" data-dismiss="modal" (click)="closeChartModal()">Close</button>
                    <button type="submit" class="btn btn-success mr-2" style="float: right;">Submit</button>

                </form>
            
        </div>

    </div>
</div>
</div>
<!---------------------------------- END OF ADD MORE CHART MODAL ------------------------>


<!---------------------------------- ADD MORE EMAIL MODAL ------------------------------->
<div class="modal fade" id="AddEmailModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
aria-hidden="true">
<div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalScrollableTitle">&nbsp;Add Email</h5>
            <button type="button" #closebutton class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <button type="button" *ngIf="addMoreButtonVisible == true" class="btn btn-primary btn-sm common-btn ml-2 mb-2"(click)="AddMoreEmail()">    
                Add Email
            </button>
                <form [formGroup]="alarmEmail" class="text-left" [ngClass]="{'form-validated': submitted}" (ngSubmit)="saveEmail()">
                    <div formArrayName="emails">
                        <div *ngFor="let mail of email.controls; let i = index;">
                           <div [formGroupName]="i">
                            <div class="row">
                                <div class="col-md-4" >
                                    <div class="form-group">
                                        <label for="">Emails</label>
                                        <select id="email" class="form-control" formControlName="email_id" required>
                                            <option hidden value="" disabled selected>Select </option>
                                                <option [value]="email.id" *ngFor="let email of emailList">{{email.email}}</option> 
                                        </select>
                                          <app-form-error [controlObj]="mail.get('email_id')" label="Email"></app-form-error>
                                          <span (click)="deleteEmail(i)" class=" mt-2 ml-2 fa fa-times text-danger"></span>
                                    </div>
                                </div>
                            </div>
                           </div>
                           
                        </div>
                    </div>

                    <button type="button" class="btn btn-secondary" style="float: right;" data-dismiss="modal" (click)="closeEmailModal()">Close</button>
                    <button type="submit" class="btn btn-success mr-2" style="float: right;">Submit</button>

                </form>
            
        </div>

    </div>
</div>
</div>
<!---------------------------------- END OF ADD MORE EMAIL MODAL ------------------------>