<ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" 
[ngClass]="{'toggled': sideBarToggled }" id="accordionSidebar" style="height: 100%;">

    <!-- Sidebar - Brand -->
    <a class="sidebar-brand d-flex align-items-left justify-content-left" href="/">
        <div class="sidebar-brand-icon rotate-n-5">
            <i class="fas fa-plug"></i>
        </div>
        <div class="sidebar-brand-text mx-3 mt-1">PRO CIM</div>
    </a>

    <!-- Divider -->
    <hr class="sidebar-divider my-0">

    <!-- Nav Item - Dashboard -->
    <li class="nav-item active" *ngIf="userType == 'ADMIN'" >
        <a class="nav-link" href="" [routerLink]="['/admin/user-list']">
            <i class="fas fa-fw fa-tachometer-alt"></i>
            <span>User Creation</span></a>
    </li>

    <!-- Nav Item - Dashboard -->
    <li class="nav-item active" *ngIf="userType == 'ADMIN'">
        <a class="nav-link" href="" [routerLink]="['/admin/department']">
            <i class="fas fa-fw fa-tachometer-alt"></i>
            <span>Department</span></a>
    </li>

    <!-- Divider -->
    <hr class="sidebar-divider">

    <!-- Nav Item - Dashboard -->
    <li class="nav-item active" *ngIf="userType == 'ADMIN'">
        <a class="nav-link" href="" [routerLink]="['/admin/designation']">
            <i class="fas fa-fw fa-tachometer-alt"></i>
            <span>Designation</span></a>
    </li>

    <!-- Divider -->
    <hr class="sidebar-divider">

    <!-- Nav Item - Dashboard -->
    <li class="nav-item active" *ngIf="userType == 'ADMIN'">
        <a class="nav-link" href="" [routerLink]="['/admin/equipment']">
            <i class="fas fa-fw fa-tachometer-alt"></i>
            <span>Equipment</span></a>
    </li>

    <!-- Divider -->
    <hr class="sidebar-divider">
    <li class="nav-item active" *ngIf="userType == 'ADMIN'">
        <a class="nav-link" href="" [routerLink]="['/admin/parameter-list']">
            <i class="fas fa-fw fa-tachometer-alt"></i>
            <span>Parameter</span></a>
    </li>

    <hr class="sidebar-divider">
    <li class="nav-item active" *ngIf="userType == 'ADMIN'">
        <a class="nav-link" href="" [routerLink]="['/admin/chart-definition-list']">
            <i class="fas fa-fw fa-tachometer-alt"></i>
            <span>Chart Definition</span></a>
    </li>

    <hr class="sidebar-divider">
    <li class="nav-item active" *ngIf="userType == 'ADMIN'">
        <a class="nav-link" href="" [routerLink]="['/admin/spc-chart']">
            <i class="fas fa-fw fa-tachometer-alt"></i>
            <span>SPC Chart</span></a>
    </li>

    <hr class="sidebar-divider">
    <li class="nav-item active" *ngIf="userType == 'ADMIN'">
        <a class="nav-link" href="" [routerLink]="['/admin/email-list']">
            <i class="fas fa-fw fa-tachometer-alt"></i>
            <span>Email Creation</span></a>
    </li>
    
    <hr class="sidebar-divider">
    <li class="nav-item active" *ngIf="userType == 'ADMIN'">
        <a class="nav-link" href="" [routerLink]="['/admin/alarm-list']">
            <i class="fas fa-fw fa-tachometer-alt"></i>
            <span>Alarm Setting</span></a>
    </li>

    <hr class="sidebar-divider">
    <li class="nav-item active" *ngIf="userType == 'ADMIN'">
        <a class="nav-link" href="" [routerLink]="['/admin/svid-chamber']">
            <i class="fas fa-fw fa-tachometer-alt"></i>
            <span>SVID-Chamber</span></a>
    </li>
<!--     Heading 
    <div class="sidebar-heading">
        Interface
    </div>

     Nav Item - Pages Collapse Menu 
    <li class="nav-item">
        <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo"
            aria-expanded="true" aria-controls="collapseTwo">
            <i class="fas fa-fw fa-cog"></i>
            <span>Components</span>
        </a>
        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
            <div class="bg-white py-2 collapse-inner rounded">
                <h6 class="collapse-header">Custom Components:</h6>
                <a class="collapse-item" href="buttons.html">Buttons</a>
                <a class="collapse-item" href="cards.html">Cards</a>
            </div>
        </div>
    </li>

     Nav Item - Utilities Collapse Menu 
    <li class="nav-item">
        <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUtilities"
            aria-expanded="true" aria-controls="collapseUtilities">
            <i class="fas fa-fw fa-wrench"></i>
            <span>Utilities</span>
        </a>
        <div id="collapseUtilities" class="collapse" aria-labelledby="headingUtilities"
            data-parent="#accordionSidebar">
            <div class="bg-white py-2 collapse-inner rounded">
                <h6 class="collapse-header">Custom Utilities:</h6>
                <a class="collapse-item" href="utilities-color.html">Colors</a>
                <a class="collapse-item" href="utilities-border.html">Borders</a>
                <a class="collapse-item" href="utilities-animation.html">Animations</a>
                <a class="collapse-item" href="utilities-other.html">Other</a>
            </div>
        </div>
    </li>

     Divider 
    <hr class="sidebar-divider">

     Heading 
    <div class="sidebar-heading">
        Addons
    </div>

     Nav Item - Pages Collapse Menu 
    <li class="nav-item">
        <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePages"
            aria-expanded="true" aria-controls="collapsePages">
            <i class="fas fa-fw fa-folder"></i>
            <span>Pages</span>
        </a>
        <div id="collapsePages" class="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
            <div class="bg-white py-2 collapse-inner rounded">
                <h6 class="collapse-header">Login Screens:</h6>
                <a class="collapse-item" href="login.html">Login</a>
                <a class="collapse-item" href="register.html">Register</a>
                <a class="collapse-item" href="forgot-password.html">Forgot Password</a>
                <div class="collapse-divider"></div>
                <h6 class="collapse-header">Other Pages:</h6>
                <a class="collapse-item" href="404.html">404 Page</a>
                <a class="collapse-item" href="blank.html">Blank Page</a>
            </div>
        </div>
    </li>

     Nav Item - Charts 
    <li class="nav-item">
        <a class="nav-link" href="charts.html">
            <i class="fas fa-fw fa-chart-area"></i>
            <span>Charts</span></a>
    </li>

     Nav Item - Tables 
    <li class="nav-item">
        <a class="nav-link" href="tables.html">
            <i class="fas fa-fw fa-table"></i>
            <span>Tables</span></a>
    </li>

     Divider 
    <hr class="sidebar-divider d-none d-md-block">-->

    <!-- Sidebar Toggler (Sidebar) -->
    <div class="fixed-bottom pl-2">
        <button (click)="sidebarToggle()" class="rounded-circle border-0" id="sidebarToggle"></button>
      </div>
</ul>