import {  FormBuilder, FormGroup, FormArray, Validators } from "@angular/forms";
import { ConfirmedValidator } from './validator'; 

let fb = new FormBuilder();
let fbGroup = new FormBuilder();
let fbArray = new FormBuilder();

let UserCreationForm: FormGroup = fb.group({
    id: [''],
    user_name: ['', Validators.required],
    email: ['', [Validators.required,Validators.email]],
    password:['',[Validators.required, Validators.minLength(6)]],
    confirm_password: ['', Validators.required],
    first_name: ['', Validators.required],
    last_name: ['', Validators.required],
    mobile: ['', Validators.required],
    department_id: ['', Validators.required],
    designation_id: ['', Validators.required],
    user_menu: fbArray.array([]),
}
,{
  validator: ConfirmedValidator('password', 'confirm_password')
}
)

export {
  UserCreationForm
}
