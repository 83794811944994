
<form [formGroup]="spcChart" class="text-left" [ngClass]="{'form-validated': submitted}" (ngSubmit)="addSpcChart()">
    <div class="row">
        <div class="col-md-3">
            <div class="form-group">
                <label for="equipment_model">Equipment Model</label>
                <select id="equipment_model" class="form-control" formControlName="equipment_model_id">
                    <option hidden value="" disabled selected>Select </option>
                        <option [value]="equipment.id" *ngFor="let equipment of equipments">{{equipment.name}}</option> 
                </select>
                <app-form-error [controlObj]="spcChart.get('equipment_model_id')" label="Equipment Model"></app-form-error>
            </div>
        </div>

        <div class="col-md-3">
            <div class="form-group">
                <label>From Date</label>
                <input type="text" class="form-control" formControlName="start_date" (ngModelChange)="dateSearch()" 
                #dp="bsDatepicker" bsDatepicker placeholder="">
            </div>
        </div>

        <div class="col-md-2">
            <div class="form-group">
                <label>From Time</label>
                <input type="text" class="form-control" formControlName="start_time" placeholder="00:00">
            </div>
        </div>

        <div class="col-md-3">
            <div class="form-group">
                <label>To Date</label>
                <input type="text" class="form-control" formControlName="end_date" (ngModelChange)="dateSearch()" 
                #dp="bsDatepicker" bsDatepicker placeholder="">
            </div>
        </div>

        <div class="col-md-2">
            <div class="form-group">
                <label>To Time</label>
                <input type="text" class="form-control" formControlName="end_time" placeholder="00:00">
            </div>
        </div>

        <div class="col-md-4">
            <div class="form-group">
                <label >SVID</label>
<!--                 <ng-select>
                    <ng-option *ngFor="let svid of svids" [value]="svid.id" >{{svid.name}}</ng-option>
                </ng-select>-->
                <select #svid id="svid" class="form-control" formControlName="svid" (change)="getParammeters(svid.value)">
                    <option [value]="svid.id" *ngFor="let svid of svids">{{svid.name}}</option> 
                </select>
<!--                <ng-multiselect-dropdown
                name="svid"
                [placeholder]="'Select SVID'"
                [data]="svids"
                formControlName="svid"
                [disabled]="disabled"
                [(ngModel)]="selectedItems"
                [settings]="dropdownSettings1"
                (onSelect)="selectSvid($event)">
            </ng-multiselect-dropdown>-->
                <app-form-error [controlObj]="spcChart.get('svid')" label="SVID"></app-form-error>
            </div>
        </div>

        <div class="col-md-4">
            <div class="form-group">
                <label >Parameter</label>
                <select id="parameter" class="form-control" formControlName="parameter">
                    <option [value]="parameter.id" *ngFor="let parameter of parameters">{{parameter.name}}</option> 
                </select>
<!--                <ng-multiselect-dropdown
                name="parameter"
                [placeholder]="'Select Parameter'"
                [data]="parameter"
                formControlName="parameter"
                [disabled]="disabled"
                [settings]="dropdownSettings2"
                (onSelect)="selectParameter()">
            </ng-multiselect-dropdown>-->
                <app-form-error [controlObj]="spcChart.get('parameter')" label="Parameter"></app-form-error>
            </div>
        </div>
        
        <div class="col-md-2">
            <div class="form-group">
                <button type="submit" class="btn btn-primary mt-4 pull-right" [disabled]="buttonDisable" *ngIf="!spcChart.value.id">
                    View Chart
                </button>
            </div>
        </div>
    </div>

<!--    <div class="row" >
        <div class="col-md-12">
            <iframe #Iframe id="Iframe" width="100%" height="100%" frameBorder="0" [src]="urlSafe" allowfullscreen></iframe>
        </div>
    </div>-->

    <div class="row" *ngIf="showChart">
        <div class="col-md-12">
            <div class="chart-wrapper">
                <canvas baseChart 
                    [datasets]="lineChartData" 
                    [labels]="lineChartLabels" 
                    [options]="lineChartOptions"
                    [colors]="lineChartColors" 
                    [legend]="lineChartLegend" 
                    [chartType]="lineChartType" 
                    [plugins]="lineChartPlugins">
                </canvas>
            </div>
        </div>
    </div>
</form>
