import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { USER_TYPE_ADMIN } from '@constants/global.constant';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService
  ) {}
  
  canActivate() : boolean {
    // Check is logged in
    if(this.authService.isLoggedIn()) {
      let userType = this.authService.getUserType();
      if(userType === USER_TYPE_ADMIN ) {
        return true;
      } else {
        this.router.navigate(["/authorized"]);
        return false;
      }
    }
    this.router.navigate(["/login"]);
    return false;
  } 
  
}
